@font-face {
    font-family: 'futura-book';
    src: url('../../fonts/futura/futura_book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura-bold';
    src: url('../../fonts/futura/futura_bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.futura-book {
    font-family: 'futura-book', Sans-Serif;
}

.futura-bold {
    font-family: 'futura-bold', Sans-Serif;
}