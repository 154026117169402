@font-face {
    font-family: 'verdana-bold';
    font-style: normal;
    src: url("../../fonts/verdana/verdana-bold.ttf") format('truetype'),
}

@font-face {
  font-family: 'verdana-regular';
  font-style: normal;
  src: url("../../fonts/verdana/verdana.ttf") format('truetype'),
}

.ql-font-verdana-bold,
.ql-font span[data-value="verdana-bold"]::before {
  font-family: "verdana-bold"
}

.ql-font-verdana-regular,
.ql-font span[data-value="verdana-regular"]::before {
  font-family: "verdana-regular"
}
