@font-face {
  font-family: "myriad-pro";
  font-style: normal;
  font-weight: 400;
  src: local("Myriad"), local("Myriad-Pro"),
    url("../fonts/myriad-pro/MyriadPro-Regular.woff2") format("woff2"),
    url("../fonts/myriad-pro/MyriadPro-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "whitney-bold";
  font-style: normal;
  font-weight: 400;
  src: local("Whitney"), local("Whitney-Bold"),
    url("../fonts/whitney-bold/whitney-bold.woff2") format("woff2"),
    url("../fonts/whitney-bold/whitney-bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "whitney-light";
  font-style: normal;
  font-weight: 400;
  src: local("Whitney"), local("Whitney-Light"),
    url("../fonts/whitney-light/whitney-light.woff2") format("woff2"),
    url("../fonts/whitney-light/whitney-light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
