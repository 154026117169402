@font-face {
    font-family: 'arial';
    src: url('../../fonts/arial/Arial.TTF') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'arial-bold';
    src: url('../../fonts/arial/Arial-Bold.TTF') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.ql-font-arial,
.ql-font span[data-value="arial"]::before {
    font-family: "arial";
}

.ql-font-arial-bold,
.ql-font span[data-value="arial-bold"]::before {
    font-family: "arial-bold";
}