@font-face {
    font-family: 'barlow-thin';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/barlow/Barlow-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'barlow-light';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/barlow/Barlow-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'barlow-regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/barlow/Barlow-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'barlow-medium';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/barlow/Barlow-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'barlow-semibold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/barlow/Barlow-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'barlow-bold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/barlow/Barlow-Bold.ttf') format('truetype');
}

.ql-font-barlow-thin,
.ql-font span[data-value="barlow-thin"]::before {
    font-family: "barlow-thin";
}

.ql-font-barlow-light,
.ql-font span[data-value="barlow-light"]::before {
    font-family: "barlow-light";
}

.ql-font-barlow-regular,
.ql-font span[data-value="barlow"]::before {
    font-family: "barlow-regular";
}

.ql-font-barlow-medium,
.ql-font span[data-value="barlow-light"]::before {
    font-family: "barlow-medium";
}

.ql-font-barlow-semibold,
.ql-font span[data-value="barlow-semibold"]::before {
    font-family: "barlow-semibold";
}

.ql-font-barlow-bold,
.ql-font span[data-value="barlow-bold"]::before {
    font-family: "barlow-bold";
}
