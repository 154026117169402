@font-face {
    font-family: 'muli-bold';
    src: url('../../fonts/muli/Muli-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'muli-light';
    src: url('../../fonts/muli/Muli-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'muli-semi-bold';
    src: url('../../fonts/muli/Muli-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'muli';
    src: url('../../fonts/muli/Muli.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ql-font-muli,
.ql-font span[data-value="muli"]::before {
    font-family: "muli";
}

.ql-font-muli-bold,
.ql-font span[data-value="muli-bold"]::before {
    font-family: "muli-bold";
}

.ql-font-muli-semi-bold,
.ql-font span[data-value="muli-semi-bold"]::before {
    font-family: "muli-semi-bold";
}

.ql-font-muli-light,
.ql-font span[data-value="muli-light"]::before {
    font-family: "muli-light";
}