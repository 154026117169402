@font-face {
  font-family: 'lusitana';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/lusitana/Lusitana-Regular.ttf') format('truetype');
}

.ql-font-lusitana,
.ql-font span[data-value="lusitana"]::before {
  font-family: "lusitana"
}
