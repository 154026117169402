/* lato-regular - latin */
@font-face {
    font-family: 'lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
        url('../../fonts/lato/lato-v13-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/lato/lato-v13-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
    font-family: 'lato-bold';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Bold'), local('Lato-Bold'),
        url('../../fonts/lato/lato-bold-webfont.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/lato/lato-bold-webfont.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ql-font-lato,
.ql-font span[data-value="lato"]::before {
    font-family: "lato";
}

.ql-font-lato-bold,
.ql-font span[data-value="lato-bold"]::before {
    font-family: "lato-bold";
}
