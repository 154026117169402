@font-face {
    font-family: 'rubik-light';
    font-weight: 300;
    font-style: normal;
    src: local("Rubik"), local("Rubik-Light"),
        url("../../fonts/rubik/Rubik-Light.woff2") format("woff2"),
        url("../../fonts/rubik/Rubik-Light.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'rubik-regular';
    font-weight: 400;
    font-style: normal;
    src: local("Rubik"), local("Rubik-Regular"),
        url("../../fonts/rubik/Rubik-Regular.woff2") format("woff2"),
        url("../../fonts/rubik/Rubik-Regular.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'rubik-medium';
    font-weight: 400;
    font-style: normal;
    src: local("Rubik"), local("Rubik-Medium"),
        url("../../fonts/rubik/Rubik-Medium.woff2") format("woff2"),
        url("../../fonts/rubik/Rubik-Medium.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'rubik-semi-bold';
    font-weight: 600;
    font-style: normal;
    src: local("Rubik"), local("Rubik-Semibold"),
        url("../../fonts/rubik/Rubik-SemiBold.woff2") format("woff2"),
        url("../../fonts/rubik/Rubik-SemiBold.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'rubik-bold';
    font-weight: 600;
    font-style: normal;
    src: local("Rubik"), local("Rubik-Bold"),
        url("../../fonts/rubik/Rubik-Bold.woff2") format("woff2"),
        url("../../fonts/rubik/Rubik-Bold.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


.ql-font-rubik-light,
.ql-font span[data-value="rubik-light"]::before {
    font-family: "rubik-light";
}

.ql-font-rubik-regular,
.ql-font span[data-value="rubik-regular"]::before {
    font-family: "rubik-regular";
}

.ql-font-rubik-medium,
.ql-font span[data-value="rubik-medium"]::before {
    font-family: "rubik-medium";
}

.ql-font-rubik-semi-bold,
.ql-font span[data-value="rubik-semi-bold"]::before {
    font-family: "rubik-semi-bold";
}

.ql-font-rubik-bold,
.ql-font span[data-value="rubik-bold"]::before {
    font-family: "rubik-bold";
}