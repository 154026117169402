@font-face {
    font-family: 'gotham-bold';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Bold'), local('Gotham-Bold'), url("../../fonts/gotham/Gotham-Bold.woff2") format('woff2'), url("../../fonts/gotham/Gotham-Bold.woff") format('woff');
}

@font-face {
    font-family: 'gotham-book';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Book'), local('Gotham-Book'), url("../../fonts/gotham/Gotham-Book.woff2") format('woff2'), url("../../fonts/gotham/Gotham-Book.woff") format('woff');
}

@font-face {
    font-family: 'gotham-light';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Light'), local('Gotham-Light'), url("../../fonts/gotham/Gotham-Light.woff2") format('woff2'), url("../../fonts/gotham/Gotham-Light.woff") format('woff');
}

@font-face {
    font-family: 'gotham-medium';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Medium'), local('Gotham-Medium'), url("../../fonts/gotham/Gotham-Medium.woff2") format('woff2'), url("../../fonts/gotham/Gotham-Medium.woff") format('woff');
}

.ql-font-gotham-bold,
.ql-font span[data-value="gotham-bold"]::before {
    font-family: "gotham-bold"
}

.ql-font-gotham-book,
.ql-font span[data-value="gotham-book"]::before {
    font-family: "gotham-book"
}

.ql-font-gotham-light,
.ql-font span[data-value="gotham-light"]::before {
    font-family: "gotham-light"
}

.ql-font-gotham-medium,
.ql-font span[data-value="gotham-medium"]::before {
    font-family: "gotham-medium"
}