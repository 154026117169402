@font-face {
    font-family: 'legacysans-book';
    src: url('../../fonts/legacy/LegacySansEF-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'legacysans-bookitalic';
    src: url('../../fonts/legacy/LegacySansEF-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'legacyserif-mediumitalic';
    src: url('../../fonts/legacy/LegacySerifEF-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'legacyserif-mediumSC';
    src: url('../../fonts/legacy/LegacySerifEF-MediumSC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.legacysans-book {
    font-family: 'legacysans-book', sans-serif;
}

.legacysans-bookitalic {
    font-family: 'legacysans-bookitalic', sans-serif;
}

.legacyserif-mediumitalic {
    font-family: 'Legacy', sans-serif;
}

.legacyserif-mediumSC {
    font-family: 'legacyserif-mediumSC', sans-serif;
}