@font-face {
    font-family: 'lora-regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/lora/Lora-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'lora-medium';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/lora/Lora-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'lora-semibold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/lora/Lora-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'lora-bold';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/lora/Lora-Bold.ttf') format('truetype');
}

.ql-font-lora-regular,
.ql-font span[data-value="lora"]::before {
    font-family: "lora-regular";
}

.ql-font-lora-medium,
.ql-font span[data-value="lora-light"]::before {
    font-family: "lora-medium";
}

.ql-font-lora-semibold,
.ql-font span[data-value="lora-semibold"]::before {
    font-family: "lora-semibold";
}

.ql-font-lora-bold,
.ql-font span[data-value="lora-bold"]::before {
    font-family: "lora-bold";
}
