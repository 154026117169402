@font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
        url('../../fonts/roboto/roboto-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/roboto/roboto-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'roboto-light';
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), local("Roboto-Light"), url("../../fonts/roboto/roboto-light.woff2") format("woff2"), url("../../fonts/roboto/roboto-light.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'roboto-medium';
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), local("Roboto-Medium"), url("../../fonts/roboto/roboto-medium.woff2") format("woff2"), url("../../fonts/roboto/roboto-medium.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'roboto-bold';
    font-style: normal;
    font-weight: 700;
    src: local("Roboto"), local("Roboto-Bold"), url("../../fonts/roboto/roboto-bold.woff2") format("woff2"), url("../../fonts/roboto/roboto-bold.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ql-font-roboto,
.ql-font span[data-value="roboto"]::before {
    font-family: "roboto";
}

.ql-font-roboto-light,
.ql-font span[data-value="roboto-light"]::before {
    font-family: "roboto-light";
}

.ql-font-roboto-medium,
.ql-font span[data-value="roboto-medium"]::before {
    font-family: "roboto-medium";
}

.ql-font-robotobold,
.ql-font span[data-value="robotobold"]::before {
    font-family: "roboto-bold";
}
