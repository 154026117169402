@font-face {
  font-family: 'pantograph-condensed-bold';
  font-style: normal;
  src: url("../../fonts/pantograph-condensed/pantograph-condensed-bold.otf") format('opentype'),
}


.ql-font-pantograph-condensed-bold,
.ql-font span[data-value="pantograph-condensed-bold"]::before {
  font-family: "pantograph-condensed-bold"
}
