@import '_variables.scss';
@import '../../node_modules/rc-color-picker-corbin/dist/rc-color-picker.css';
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

.admin-main {
  margin: 12px !important;
  margin-top: 70px !important;
  padding: 16px !important;
}

body {
  background-color: $main-background;
  margin: 0 !important;
}

.bread-crumbs {
  padding: 0px;
  overflow: hidden !important;
  position: fixed !important;
  top: 46px !important;
  left: 0px;
  width: 100%;
  border-top: 1px solid rgb(172, 172, 172);
  border-bottom: 1px solid rgb(172, 172, 172);
  background-color: #d9d9d9 !important;
  z-index: 1px;
}

.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexright {
  display: flex;
  justify-content: right;
  align-items: right;
}

.ant-menu {
  height: 45px;
}

.ant-tabs-nav {
  padding-left: 12px;
}

#center_tabs_container > .ant-tabs-top > .ant-tabs-nav {
  background-color: white;
  z-index: 5;
}

/* Get rid of underline on hover/active */
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom: none;
  color: inherit;
}


header {
  margin-bottom: 12px;
  background-color: #d9d9d9 !important
}

.systemadmin-menu {
  padding-right: 0;
}

.form-field-wrapper {
  padding: 6px;
}

.file-input-wrapper input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input-wrapper input[type="file"] + label {
  border: #d9d9d9 1px solid;
  border-radius: 3px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  padding-right: 15px;
  padding-left: 15px;
  display: inline-block;
  margin-bottom: 10px;
}

.file-input-wrapper input[type="file"]:focus + label,
.file-input-wrapper input[type="file"] + label:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.file-input-wrapper input[type="file"]:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.file-input-wrapper input[type="file"] + label {
  cursor: pointer; /* "hand" cursor */
}

.file-input-wrapper input[type="file"] + label * {
  pointer-events: none;
}

.file-input-wrapper .file-name,
.file-input-wrapper .file-remove {
  margin-top: 30px;
  word-break: break-all;
}

.file-input-wrapper .file-preview {
  word-break: break-all;
  margin-top: 25px;
}

.file-input-wrapper .file-name-no-title {
  word-break: break-all;
}

.file-input-wrapper .file-preview img,
.file-input-wrapper .file-preview-no-title img {
  max-width: 50px;
  max-height: 40px;
}

.form-wrapper {
  padding: 12px;
}

.form-field-error {
  color: red;
  font-weight: bold;
}

.form-input-label {
  font-weight: bold;
  line-height: 1.9em;
  color: rgba(24, 41, 52, .85)
}

.form-submit-button {
  text-transform: uppercase;
}

.auth-container {
  //background-repeat: no-repeat;
  //background-size: cover;
  //height: 100%;

  &_bg {
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
  }

  &_form {
    margin: 0 40px;

    &-panel {
      margin: 0;
      position: absolute;
      top: 35%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 400px;
    }
  ;

    &-container {
      height: 100vh;
      background: linear-gradient(#5197F3, #1864B7);
    }

    &-input {
      margin: 0 15px;

      &-container {
        margin: 20px auto 15px;
      }

      &-text {
        border-radius: 15px;
      }

      &-recaptcha {
        margin-bottom: 25px;

        & > div {
          margin: 0 auto;

          & > div {
            margin: 0 auto;
          }
        }

        .rc-anchor {
          border-radius: 30px;
        }
      }
    }
  }

  &_logo {
    width: 100%;

    &-link {
      cursor: pointer;
    }
  }

  &_h2 {
    color: white !important;
    font-size: 1.3em;
    font-weight: 600;
  }

  &_h3 {
    color: white !important;
    font-size: 1em;
  }

  &_button {
    color: white;
    font-weight: bold;
    background: #0E305C;
    border: none;
    border-radius: 15px;
  }

  &_buttons {

  }

  &_link {
    color: white !important;
    text-decoration: white underline !important;
  }

  &_links {
    margin: 40px 0 0;
  }

}

.auth-container-password-reset-request {
  &_form {
    &-panel {
      top: 30%;
    }
  }
}

.login {
  &-form {
    text-align: center;

    &-field {
      padding: 4px;
      margin: auto;
    }

    &-submit {
      width: 50%;
    }
  }
}

.pull-right {
  float: right;
}

.rcorners1 {
  border-radius: 25px;
  padding: 8px;
  border-style: 'dotted';
  height: 50;
  color: 'white';
  border-color: 'white';
  text-align: 'center';
}

.s-left-panel-wrap {
  border-right: 1px solid #d1d1d1;
  transition: .3s ease-out;

  &.slide-in {
    transform: translateX(0%);
  }

  &.slide-out {
    transform: translateX(-100%);
  }
}

.s-panel-wrap {
  transition: .3s ease-out;
  background-color: white;
  border-left: 1px solid #d1d1d1;
}

.slide-in {
  transform: translateX(0%);
}

.slide-out {
  transform: translateX(100%);
}

.s-panel {
  color: black;
  overflow: auto;

  &-bottom-border {
    border-bottom: 1px solid #f0f0f0;
  }

}

.search-custom-collapse .search-custom-panel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0
  }
}

.ReactVirtualized__Grid {
  outline: none;
}

.hide {
  display: none;
}

.ant-progress-circle .ant-progress-text {
  color: white;
}

.margin-for-open-button {
  .ant-tabs-nav.ant-tabs-nav-animated {
    margin-left: 50px;
  }
}

#center_tabs_container {
  .ant-tabs-bar.ant-tabs-top-bar {
    display: flex;
    flex-direction: row;
    background: white;
    z-index: 5;
    margin-bottom: 0;
  }

  .ant-tabs-extra-content {
    max-width: 100%;
    background: white;
    z-index: 5;
    order: 2;
  }

  .ant-tabs-nav-container {
    background: white;
    z-index: 5;
    flex: 1;
    margin-bottom: -2px;
    border-bottom: 1px solid #e8e8e8;
  }
}

.file-input-wrapper input[type="file"] + label.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.file-input-wrapper input[type="file"] + label.disabled:hover {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}

.center-tab {
  z-index: 10;
}

.react-crop-background {
  margin-left: 1px;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
  linear-gradient(-45deg, #808080 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #808080 75%),
  linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.ant-modal-close-x {
  color: 'white'
}

.dynamic-file-input {
  .dynamic-file-input_h2 {
    font-weight: normal;
    font-size: 0.9em;
    line-height: 2.0em;
    color: #A0A0A7
  }

  .dynamic-file-input_image-container {
    padding-top: 10px;
  }

  .dynamic-file-input_add-button {
    .preview-gallery_inner {
      height: 100% !important;
      width: 100% !important;

      &--disabled {
        height: 96% !important;
      }

      svg {
        width: 0.9em;
      }
    }
  }

  &_add-button {
    .preview-gallery_inner {
      height: 90% !important;
      width: 90% !important;
    }
  }
}

.carousel-media-file-input {

  &_image-container {
    padding-top: 10px;
  }

  &_card:first-child {
    margin-left: auto;
  }

  &_card {
    opacity: 0.5;

    &-active {
      opacity: 1;

      .preview-gallery_container-full {
        //margin-top: 2px;

        .preview-gallery_outer {
          border: solid 3px #2999FF;
          padding-top: 94%;

          .preview-gallery_inner {
            top: -0px
          }
        }
      }

      .preview-gallery__icons-video-center {
        margin: 22px 26px;
      }
    }
  }
}

.preview-gallery {

  &_container {
    width: 100px;
    background-color: #FFFFFF;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080),
    linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080);
    background-size: 5px 5px;
    background-position: 0 0, 2.5px 2.5px;

    &-sm {
      width: 10%;
    }


    &-full {
      width: 82px;
    }

    &-button {
      padding-right: 0px !important;
      padding-bottom: 0px !important;

      .preview-gallery_inner {
        height: 95%;
        width: 95%;
      }
    }
  }

  &_outer {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    //border: solid 2px #2999FF;

    &:hover {
      .preview-gallery__icons-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .preview-gallery__icons-overlap {
        display: block;
      }
    }
  }

  &_inner-button {
    width: 100%;
    height: 100%;

    svg {
      left: -3px;
    }

    span {
      svg {
        left: -3.5px !important;
      }
    }
  }

  &_inner {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #CCC;
  }

  &__icons {
    &-container {
      display: none;
      background-color: black;
      //opacity: 0.4;
      width: 100%;
      height: 100%;
    }

    &-overlap {
      display: none;
      position: absolute;
      background-color: black;
      opacity: 0.4;
      width: 100%;
      height: 100%;
    }

    &-video {
      &-container {
        display: block;
        background-color: black;
        //opacity: 0.4;
        width: 100%;
        height: 100%;
      }

      &-overlap {
        display: block;
        position: absolute;
        background-color: black;
        opacity: 0.4;
        width: 100%;
        height: 100%;
      }

      &-center {
        margin: 24px 28px;

        svg {
          cursor: inherit !important;
        }
      }
    }

  }

  &_big-icon {
    font-size: 1.5em;
  }

  &_icon {
    position: absolute;
    font-size: 1.2em;
    color: white;
    z-index: 100;

    &-left {
      position: absolute;
      top: 40%;
      left: 15%;
    }

    &-right {
      position: absolute;
      top: 40%;
      right: 15%;
    }

    &-center {

    }
  }

  &_outer-media {
    border: 1px solid grey;
  }

  &_image-container {

    div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      //width: 100%;
      //height: auto;
      //position: relative;
      //overflow: hidden;
      //padding-top: 100%;

    }

    img {
      object-fit: cover;
      //object-fit: contain;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      //height: 100%;

      //max-width: 100%;
      //max-height: 100%;
      //position: absolute;
      //top: 0;
      //bottom: 0;
      //left: 0;
      //right: 0;
    }

    //
    //.preview-gallery_spinner {
    //  padding: 30%;
    //}

    svg {
      padding: 30%;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &_spinner {
    padding: 12%;

    &-disabled {
      padding: 12%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: gray;
      opacity: 0.5;

      svg {
        cursor: default !important;
      }
    }
  }
}

.modal_content_manager {
  .ant-modal-title {
    font-size: 18px;
  }

  .ant-modal-close-icon {
    font-size: 20px;
  }
}

.content-manager {
  //height: 650px;
  height: auto;

  &_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    //height: 100%;
    height: auto;

    &-left {
      flex: 3;

      .carousel-media-file-input_image-container {
        justify-content: flex-end;
      }
    }

    &-right {
      //background-color: grey;
      padding: 0 20px;
      flex: 1;
    }

    &-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20%;
      left: 0;
    }
  }

  &_section {
    display: flex;
    width: 100%;

    &-top {
      flex: 4;
    }

    &-bottom {
      background: #F0F0F0;
      flex: 1;
      display: flex;
      height: 110px;

      .preview-gallery_container {
        padding-right: 10%;
        padding-bottom: 8%;
        background: none;
      }

      &-left {
        border-right: black 1px solid;
        margin: 17px;
        flex: 3;
        position: relative;

        .carousel-media-file-input_image-container {
          padding-top: 0;
          padding-right: 10px;
          overflow: auto;
          display: flex;
          // do not use justifi-conent it will hide scroll bar
          // https://stackoverflow.com/questions/36130760/use-justify-content-flex-end-and-to-have-vertical-scrollbar
          // (it is a bug https://bugzilla.mozilla.org/show_bug.cgi?id=1042151)
          //justify-content: flex-end;
          flex-wrap: unset;
        }

        .carousel-media-file-input {
          //width: 65%;
        }
      }

      &-right {
        flex: 1;
        padding: 18px 0 10px 15px;
      }

      &-container {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .carousel-media-file-input {
        width: 100%;
      }
    }
  }

  &_image {
    position: relative;
    display: flex;
    justify-content: center;

    &-container {
      // https://css-tricks.com/planning-for-responsive-images/
      .image-loader {
        //display: grid;
        display: flex;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        border: grey 1px solid;

        img {
          object-fit: cover;
          height: 370px; // check this value in responsive
        }

        svg {
          height: 380px;
          width: 10em;
        }
      }
    }

    &-label {
      position: absolute;
      left: 46.5%;
      top: 5%;

      &-container {
        position: relative;
        width: 50px;
        height: 25px;
        text-align: center;
      }

      &-overlap {
        background: #FFFFFF;
        position: absolute;
        width: 100%;
        height: 100%;
        border: solid 1px #D5D9DB;
      }

      &-text {
        top: 5px;
        left: 0px;
        position: absolute;
        width: inherit;
        font-weight: bolder;
        color: #808080;
        text-transform: uppercase;
        font-size: 11px;
      }
    }

    &-button {
      color: #40B2F9;
      background: #E6EAE9;
      z-index: 1000;

      &-prev {
        position: absolute;
        left: 3%;
        top: 50%;
      }

      &-next {
        position: absolute;
        right: 3%;
        top: 50%;
      }
    }

    &-buttons {
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;

      button {
        width: 160px;
        font-size: 13px;
        font-weight: bold;
      }

      &-left {
        button {
          margin-right: 20px;
        }
      }

      &-right {
        button {
          background: #F52621;
          color: white;
        }
      }
    }
  }

  &_spinner {
    position: relative;

    svg {
      position: absolute;
      left: 42%;
      top: 110px;
    }
  }

  &_form {
    &-header {
      padding: 0 0 20px;

      &-h1 {
        font-size: 1.6em;
        font-weight: bold;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-h2 {
        font-size: 0.8em;
        font-weight: bold;
        font-style: italic;

      }
    }

    &-h1 {
      font-size: 1em;
      font-weight: bold;
      padding: 5px 0;
    }

    &-notes {
      font-weight: lighter;
      font-size: 0.8em;
    }

    &-body {
    }

    &-field {
      padding: 5px 0 15px;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
    }

    &-button {
      margin-left: 10px;
      text-transform: uppercase;
    }

  }

  &_footer {
    display: flex;
    padding: 40px 0 0;
    justify-content: flex-end;
  }

  &_view {
    &-add-button {
      .preview-gallery_inner {
        height: 90% !important;
        width: 90% !important;
      }
    }
  }
}

.image-cropper {

  &_container {
    &-dark {
      background: darkblue;
    }

    &-white {
      background-color: #FFFFFF;
      background-image: linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080),
      linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080);
      background-size: 15px 15px;
      background-position: 0 0, 7.5px 7.5px;
    }
  }

  &_button {
    text-transform: uppercase;
  }
}

.content-manager-widget {
  position: relative;
  display: flex;
  justify-content: center;

  &_images {
    position: relative;

    &:hover {
      .content-manager-widget_buttons-main {
        display: block;
      }
    }

    &-container {
      display: flex;

      .ant-carousel {
        width: 40px;
      }

      .preview-gallery_container {
        height: 100%;
        width: 100%;
      }
    }

    &-processing {
      width: 37px;
      border: solid 1px black;
      height: 37px;
      padding: 9px;

      &-icon {
        //font-size: 10px;
      }
    }
  }

  &_carousel {
    display: flex;
  }

  &_buttons {
    position: relative;
    width: 25px;

    &-main {
      display: none;
      position: absolute;
      top: 0%;
      left: 0%;
    }

    &-add {
      position: absolute;
      top: -20px;
      left: 0%;
    }
  }

  &_button {
    color: #40B2F9;
    background: #E6EAE9;
    z-index: 1000;
    width: 15px;
    height: 13px;
    padding: 0;

    svg {
      font-size: 0.9em;
      top: 0px;
      position: absolute;
    }

    &-prev {
      position: absolute;
      left: 3%;
      top: 32%;

      svg {
        left: 2px;
      }
    }

    &-next {
      position: absolute;
      right: 3%;
      top: 32%;

      svg {
        right: 1px;
      }
    }

    &-overlap {
      background: black;
      width: 40px;
      height: 40px;
      opacity: 0.5;
    }

    &-main {
      position: absolute;
      top: 34%;
      left: 50%;
      background: transparent;
      color: white;
    }
  }
}

.content-uploader {
  height: 100%;

  &_dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  &_buttons {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    &-main {
      display: flex;
      flex-direction: column;

      .ant-btn {
        margin: 4px 0;
      }
    }

    &-cancel {
      justify-content: center;
      display: flex;
      margin: 40px 0 0;
    }
  }

  &_dnd {
    margin: 40px 0;
  }

  &_h1 {
    font-size: 1.5em;
    font-weight: bold;
  }

  &_image {
    display: flex;
    justify-content: center;
  }

  &_separator {
    margin: 10px 0;
    display: flex;

    &-line {
      border-bottom: 1px grey solid;
      /* width: 100%; */
      /* background: green; */
      justify-content: center;
      width: 100px;
      height: 1px;
      margin: 10px 20px;
    }
  }
}

.flex-top-left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: center;
}

.flex-top-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.flex-top-right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: center;
}

.flex-bottom-left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: baseline;
  align-content: center;
}

.flex-bottom-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.flex-bottom-right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-center-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.background-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.media-progress-status {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 300px;
  height: 230px;
  z-index: 20;
  background: white;
  border: solid 1px grey;

  &-minimized {
    height: 4.5%;
    //bottom: 50%;
  }

  &_header {
    display: flex;
    padding: 10px;
    background: #323232;
    color: white;

    &-title {
      flex-grow: 2;
    }

    &-buttons {
      width: 60px;
      padding: 0 5px;
      display: flex;
      justify-content: space-between;
    }
  }

  &_body {
    padding: 10px;

    &-empty {
      justify-content: center;
      padding: 5%;
    }

    &-col {
      padding: 5px 0;

      &-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 10px;
      }
    }

    &-row {
      width: '100%'
    }

  }

  &_icon {
    &-loading {
      color: #8a9ba8;
    }

    &-error {
      color: red;
    }

    &-complete {
      color: green;
    }

  }
}

.switch-company-menu {
  cursor: pointer;

  margin-left: 20px;
}

.unsaved-changes-modal {
  &_header {
    text-align: center;
    font-size: 18px;
  }

}

.button-delete > button:hover {
  background: #f36460 !important;
}

.button-delete > button {
  background: red !important;
  color: white !important;
}

#folder_detail_form_wrapper {
  form {
    // select first element (all input field in form)
    > div:first-child {
      // add space for button (now it is flatting
      margin-bottom: 60px;
    }

    > div:last-child {
      position: absolute;
      text-align: right;
      bottom: 5px;
      right: 40px
    }
  }
}

.add_folder_form {
  height: 100%;
  position: relative;

  .form-wrapper {
    height: 100%;
  }

  form {
    height: 100%;

    // select first element (all input field in form)
    > div:first-child {
      // add space for button (now it is flatting
      height: calc(100% - 150px);
      overflow: auto;
    }

    > div:last-child {
      width: 100% !important;
      border-top: #F0F0F0 solid 1px;
      padding: 10px 16px !important;
      position: absolute;
      bottom: 5px;
      & .form-field-wrapper {
        padding: 0px;
      }
    }
  }
}

#center_tabs_container {
  .add_folder_form {
    .dynamic-form-name {
      font-size: 24px;
    }
    form {
      .image-select-input-header {
        font-size: 24px;
        text-align: left!important;
        font-weight: bold;
      }
    }
  }
}

.ant-modal-content {
  .add_folder_form {
    form {
      > div:first-child {
        // add space for button (now it is flatting
        height: auto;
        &:not(:has(.image-select-input-header)) {
          margin-bottom: 56px;
        }

      }

      > div:last-child {
        width: calc(100% + 42px) !important;
        margin: -24px;
      }
    }
  }
}

.list-view {
  &_button {
    text-transform: uppercase;
  }

  &_header {
    &-hover {
      background: #2999FB;
      color: white;
      opacity: 0.5;
      padding: 15px 0px 15px 0px;

      .filter-action_icon-filtered {
        color: white
      }
    }
  }
}

.filter-action {
  &_container {
    position: relative;
  }

  &_buttons {
    position: absolute;
    right: 0;
    top: -12px;
    padding: 12px 5px;

    //&:hover {
    //  background: #808080;
    //  opacity: 0.6;
    //}
  }

  &_icon {
    font-size: 11px;
    opacity: 0.6;

    &-filtered {
      color: #1890ff;
    }

    &:hover {
      color: white;
    }
  }
}

.filter-action-modal {
  &_body {
    padding-bottom: 5px;
  }

  &_buttons {
    border-top: 1px solid #f0f0f0;
    padding-top: 10px;
  }
}

// scrolbar style

/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.no-pointer-events {
  pointer-events: none;
}

.resource-detail {
  .ant-list-header {
    background: #FAFAFA;
    width: 100%;
  }

  &_header {

    &-text {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &_panel {
    .ant-collapse-header {
      font-size: 18px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }

  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  & .ant-empty {
    margin: 0;

    &-image {
      display: none;
    }
  }

  &_actions {
    display: flex;
    margin: 6px 0;
    justify-content: flex-end;
    position: absolute;
    bottom: 5px;
    right: 20px;
  }
}

.resource-content-library-detail {
  width: 100%;

  &_header {

    &-text {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.resource-content-library-associated {
  margin: 0;
  width: 100%;

  &_associated {
    &-side {
      display: flex;

      &-left {
        margin-top: 2px;
        margin-right: 10px;
      }

      &-right {


      }

      &-folder {
        font-size: 18px;
      }

      &-path {
        font-weight: 600;
        color: #00000070
      }

      &-icon {
        color: #2999FB;
      }
    }
  }

  &_view {
    &-side {
      &-icon {
        font-size: 16px;
      }
    }
  }
}

.resource-content-library-user-generated {
  width: 100%;

  &_header {

    &-text {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &_name {
    width: 90%;
  }

  &_notes {
    display: flex;

    &-list {
      width: 90%;
    }

    &-note {

    }

    &-extra {
      font-weight: 600;
    }

    &-separator {
      border-bottom: solid 1px #80808042;
      margin: 15px 0 10px;
    }

    &-actions {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      margin-right: 8px;

      &-icon {
        color: #2699FB;
        padding: 2px;
        font-size: 18px !important;
        margin-top: 5px;
      }

      &-add {

      }

      &-edit {
        margin-left: 5px;
      }

      &-delete {
        margin-left: 5px;
      }
    }

    &-form {
      margin: 0 0 10px 0;

      &-buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &_tag {
    &-actions {
      &-icon {
        color: #2699FB;
        //background: #2699FB;
        padding: 2px;
        font-size: 18px !important;
        margin-top: 5px;
      }
    }

    &-itemtag {
      margin: 2px;
      padding: 2px 5px;
      font-size: 14px;
      text-transform: capitalize;
    }

  }

  &_listitem {
    position: relative;
    min-height: 40px;

    &-left {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }

    &-right {
      width: 80%
    }

    &-tags {
      //&-empty {
      //  min-height: 40px;
      //}

      &-content {
        //margin: 0px 0px 0 80px;
      }
    }

    &-notes {
      min-height: 40px;
      padding-right: 15px !important;
    }

    &-status {
      display: flex;
      align-items: self-start;

      &-title {

      }
    }

    &-header {
      height: 30px
    }

    &-title {
      width: 18%;
    }

    &-content {
      width: 88%;
      margin: 40px 0px 0 80px;
    }

    &-action {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 10px;
      right: 23px;
      width: 5%;

    }

    &-name {
      display: flex;
      align-items: self-start;

      &-title {

      }
    }
  }

  &_actions {
    display: flex;
    margin: 15px 0;
    justify-content: flex-end;
  }

  &_available {
    &-form {
      .form-field-wrapper {
        padding: 0;
      }
    }
  }
}


.like-alink {
  cursor: pointer;
  color: #2699FB;
  text-decoration: none;
}

.like-alink:hover {
  text-decoration: underline;
  color: #2699FB
}

.admin-display-table .ant-table .ant-table-tbody > tr:hover > td {
  background: #2699FB;
  color: white !important;

  & a {
    color: white !important;
  }
}

#center_tabs_container .ant-tabs-content {
  width: 100% !important;
  // height: calc(100% - 62px)!important;
  height: 100% !important;
}

#center_tabs_container .ant-tabs-content-holder {
  width: 100% !important;
  height: 100% !important;
}

.preview-transition {
  height: 100%;
  width: 100%;

  & img {
    width: 100%;
  }
}

.preview-transition-natural {
  height: 100%;
  width: 100%;
}

.preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eugene {
  &-template-001 {

  }

  &-template-002 {
    &_body-text {
      br {
        content: none !important;
      }
    }

    &_subhead {
      br {
        content: none !important;
      }
    }
  }

  &-template-003 {
    &_body-text {
      br {
        content: none !important;
      }
    }

    &_subhead {
      br {
        content: none !important;
      }
    }
  }

  &-template-004 {
    &_body-text {
      br {
        content: none !important;
      }
    }

    &_subhead {
      br {
        content: none !important;
      }
    }
  }

  &-template-005 {
    &_body-text {
      br {
        content: none !important;
      }
    }

    &_subhead {
      br {
        content: none !important;
      }
    }
  }
}

.auth-password-input {
  &-popover {
    .ant-popover-arrow {
      border-left-color: #0E305C !important;
      border-bottom-color: #0E305C !important;
      //background: #0E305C;
    }

    .ant-popover-inner {
      background: transparent;
      //box-shadow: rgba(0,0,0,0.8) 0 0 10px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }

    .ant-popover-inner-content {
      background: #0E305C;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }

    &_container {
      color: white;
    }

    &_header {
      font-size: 16px;
      padding-bottom: 10px;
    }

    &_body {
      font-size: 12px;
    }

    &_rule {
      display: flex;

      &-check {

      }

      &-label {

      }
    }
  }

  &_input {
    border-radius: 20px;
  }
}

.form-input-color {
  &_container {
    position: relative;
    width: 100px;
    padding-right: 1%;
    padding-bottom: 1%;

    &:hover .form-input-color_outer {
      opacity: 0.3;
    }

    &:hover .form-input-color_outer-overlay {
      opacity: 1;
    }

    &-sm {
      width: 10%;
    }


    &-full {
      width: 82px;
    }

    &-button {
      padding-right: 0px !important;
      padding-bottom: 0px !important;
      border-radius: 10px !important;
      background-color: transparent !important;
    }
  }

  &_outer {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    //border: solid 2px #2999FF;

    &-overlay {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 5px;
      text-align: center;
    }
  }

  &_inner-button {
    width: 100%;
    height: 100%;

    svg {
      left: -3px;
    }

    span {
      svg {
        left: -3.5px !important;
      }
    }
  }

  &_inner {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    //border: 1px solid #CCC;

    &--disabled {
      background: #f5f5f5 !important;
    }
  }

  &_tooltip {
    & .ant-popover-inner-content {
      padding: 5px;
    }

    &-body {
      padding-bottom: 5px;
    }

    &-buttons {
      border-top: 1px solid #f0f0f0;
      padding: 5px 0;
      display: flex;
      justify-content: flex-end;
    }

    &-button {

    }
  }
}

.configuration {
  &_placeholder {
    &-form {
      .form-input-color_container {
        width: 150px;
        border-radius: 10px;
      }
    }
  }
}

.image-carousel-2103 {
  & > .slick-dots {
    & > ul {
      padding-inline-start: 0;
    }
  }
}


.scrollable-fix-container-eugene-donor-list {
  &_fade-top {
    -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }

  &_fade-bottom {
    -webkit-mask-image: -webkit-gradient(linear, left 80%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }
}


.scrollable-fix-container {
  //-webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  //-webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(black), to(rgba(0, 0, 0, 0)));
  &_fade-top {
    -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }

  &_fade-bottom {
    -webkit-mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }

  &_fade-both {
    -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0))), -webkit-gradient(linear, left 50%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    -webkit-mask-composite: source-in;
  }

  &_container {
    //margin-top: 20px;
    //margin: 0 auto;
    //color: white;

    //-webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    //
    //&-with-scroll {
    //  overflow: scroll;
    //}
  }

  &_text {
    //background-image: linear-gradient(180deg,#000000 0%,rgba(0,0,0,0));
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    //position:relative;
    //display:inline-block;
    //font-size: 50px;

    //padding-bottom: 35%
  }
}

.scrollable-menu-with-one-giving-level {
  &_fade-top {
    -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }

  &_fade-bottom {
    -webkit-mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }
}

.backup-buttons {
  display: flex;
  justify-content: space-evenly;
}

.webview-buttons {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 1500px) {
  .backup-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    button {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1765px) {
  .webview-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    button {
      margin-bottom: 10px;
    }
  }
}

/* Using plain CSS */
@media (max-width: 1400px) {
  .preview-gallery-container {
    width: 25%;
    padding-right: 3%;
    padding-bottom: 3%;
  }

}

/* sm */
@media (max-width: 576px) {
  .auth-container_form-panel {
    width: 100%;
  }
}

.ant-tabs.ant-tabs-top {
  overflow: visible
}
.ant-collapse-content.ant-collapse-content-active {
  overflow: visible;
}

.grid-bg, .preview-image {
  background-color: #FFFFFF;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080), linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
}

.zoom-fields-wrapper {
  opacity: 0.1;
  transition: opacity 600ms ease-in-out;
  -webkit-transition: opacity 600ms ease-in-out;
  -moz-transition: opacity 600ms ease-in-out;
  &:hover {
    opacity: 1;
  }

  .zoom-container {
    background: #505050;
    border-radius: 20px;
    padding: 5px;
  }
}

.zoom-icon {
  color: white;
}

.zoom-input .ant-select-selector {
  border-radius: 10px!important;
}

.zoom-input {
  margin-left: 20px!important;
  margin-right: 20px!important;
}
