@charset "UTF-8";
@font-face {
  font-family: "abc-social-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/abc-social/ABCSocial-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "abc-social-medium";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/abc-social/ABCSocial-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "abc-social-book";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/abc-social/ABCSocial-Book.woff2") format("woff2");
}
@font-face {
  font-family: "abc-social-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/abc-social/ABCSocial-Bold.woff2") format("woff2");
}
.ql-font-abc-social-regular,
.ql-font span[data-value=abc-social-regular]::before {
  font-family: "abc-social-regular";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

.ql-font-abc-social-medium,
.ql-font span[data-value=abc-social-medium]::before {
  font-family: "abc-social-medium";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

.ql-font-abc-social-book,
.ql-font span[data-value=abc-social-book]::before {
  font-family: "abc-social-book";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

.ql-font-abc-social-bold,
.ql-font span[data-value=abc-social-bold]::before {
  font-family: "abc-social-bold";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

@font-face {
  font-family: "aleo-light";
  src: url("../../fonts/aleo/Aleo-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "aleo-bolditalic";
  src: url("../../fonts/aleo/Aleo-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "aleo-bold";
  src: url("../../fonts/aleo/Aleo-Bold.ttf") format("truetype");
}
.aleo-light {
  font-family: "aleo-light", Sans-Serif;
}

.aleo-bold {
  font-family: "aleo-bold", Sans-Serif;
}

.aleo-bolditalic {
  font-family: "aleo-bolditalic", Sans-Serif;
}

@font-face {
  font-family: "arial";
  src: url("../../fonts/arial/Arial.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "arial-bold";
  src: url("../../fonts/arial/Arial-Bold.TTF") format("truetype");
  font-weight: 600;
  font-style: normal;
}
.ql-font-arial,
.ql-font span[data-value=arial]::before {
  font-family: "arial";
}

.ql-font-arial-bold,
.ql-font span[data-value=arial-bold]::before {
  font-family: "arial-bold";
}

@font-face {
  font-family: "bembo-semibold";
  font-style: normal;
  font-weight: 600;
  src: local("Bembo"), local("Bebmbo-Semibold"), url("../../fonts/bembo/BemboStd-Semibold.woff2") format("woff2"), url("../../fonts/bembo/BemboStd-Semibold.woff") format("woff");
}
.ql-font-bembo-semibold,
.ql-font span[data-value=bembo-semibold]::before {
  font-family: "bembo-semibold";
}

@font-face {
  font-family: "barlow-thin";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/barlow/Barlow-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "barlow-light";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/barlow/Barlow-Light.ttf") format("truetype");
}
@font-face {
  font-family: "barlow-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "barlow-medium";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/barlow/Barlow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "barlow-semibold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/barlow/Barlow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "barlow-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/barlow/Barlow-Bold.ttf") format("truetype");
}
.ql-font-barlow-thin,
.ql-font span[data-value=barlow-thin]::before {
  font-family: "barlow-thin";
}

.ql-font-barlow-light,
.ql-font span[data-value=barlow-light]::before {
  font-family: "barlow-light";
}

.ql-font-barlow-regular,
.ql-font span[data-value=barlow]::before {
  font-family: "barlow-regular";
}

.ql-font-barlow-medium,
.ql-font span[data-value=barlow-light]::before {
  font-family: "barlow-medium";
}

.ql-font-barlow-semibold,
.ql-font span[data-value=barlow-semibold]::before {
  font-family: "barlow-semibold";
}

.ql-font-barlow-bold,
.ql-font span[data-value=barlow-bold]::before {
  font-family: "barlow-bold";
}

@font-face {
  font-family: "cachet-pro-bold";
  font-style: normal;
  src: url("../../fonts/cachet-pro/Cachet Std Bold.otf") format("opentype");
}
@font-face {
  font-family: "cachet-pro-regular";
  font-style: normal;
  src: url("../../fonts/cachet-pro/Cachet Std Book.otf") format("opentype");
}
@font-face {
  font-family: "cachet-pro-medium";
  font-style: normal;
  src: url("../../fonts/cachet-pro/Cachet Std Medium.otf") format("opentype");
}
.ql-font-cachet-pro-bold,
.ql-font span[data-value=cachet-pro-bold]::before {
  font-family: "cachet-pro-bold";
}

.ql-font-cachet-pro-regular,
.ql-font span[data-value=cachet-pro-regular]::before {
  font-family: "cachet-pro-regular";
}

.ql-font-cachet-pro-medium,
.ql-font span[data-value=cachet-pro-medium]::before {
  font-family: "cachet-pro-medium";
}

@font-face {
  font-family: "crimson-regular";
  src: url("../../fonts/crimson/CrimsonText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "crimson-semi-bold";
  src: url("../../fonts/crimson/CrimsonText-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.crimson-semi-bold {
  font-family: "crimson-semi-bold", Sans-Serif;
}

.crimson-regular {
  font-family: "crimson-regular", Sans-Serif;
}

/* fira-sans-regular - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Fira Sans Regular"), local("FiraSans-Regular"), url("../../fonts/fira-sans/fira-sans-v7-latin-regular.woff2") format("woff2"), url("../../fonts/fira-sans/fira-sans-v7-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.gfont_fira {
  font-family: "Fira Sans", Sans-Serif;
}

@font-face {
  font-family: "futura-book";
  src: url("../../fonts/futura/futura_book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "futura-bold";
  src: url("../../fonts/futura/futura_bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.futura-book {
  font-family: "futura-book", Sans-Serif;
}

.futura-bold {
  font-family: "futura-bold", Sans-Serif;
}

@font-face {
  font-family: "gilroy-medium";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/gilroy/gilroy-medium.ttf") format("truetype");
}
.ql-font-gilroy-medium,
.ql-font span[data-value=gilroy-medium]::before {
  font-family: "gilroy-medium";
}

@font-face {
  font-family: "lusitana";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/lusitana/Lusitana-Regular.ttf") format("truetype");
}
.ql-font-lusitana,
.ql-font span[data-value=lusitana]::before {
  font-family: "lusitana";
}

@font-face {
  font-family: "gotham-bold";
  font-style: normal;
  font-weight: 400;
  src: local("Gotham Bold"), local("Gotham-Bold"), url("../../fonts/gotham/Gotham-Bold.woff2") format("woff2"), url("../../fonts/gotham/Gotham-Bold.woff") format("woff");
}
@font-face {
  font-family: "gotham-book";
  font-style: normal;
  font-weight: 400;
  src: local("Gotham Book"), local("Gotham-Book"), url("../../fonts/gotham/Gotham-Book.woff2") format("woff2"), url("../../fonts/gotham/Gotham-Book.woff") format("woff");
}
@font-face {
  font-family: "gotham-light";
  font-style: normal;
  font-weight: 400;
  src: local("Gotham Light"), local("Gotham-Light"), url("../../fonts/gotham/Gotham-Light.woff2") format("woff2"), url("../../fonts/gotham/Gotham-Light.woff") format("woff");
}
@font-face {
  font-family: "gotham-medium";
  font-style: normal;
  font-weight: 400;
  src: local("Gotham Medium"), local("Gotham-Medium"), url("../../fonts/gotham/Gotham-Medium.woff2") format("woff2"), url("../../fonts/gotham/Gotham-Medium.woff") format("woff");
}
.ql-font-gotham-bold,
.ql-font span[data-value=gotham-bold]::before {
  font-family: "gotham-bold";
}

.ql-font-gotham-book,
.ql-font span[data-value=gotham-book]::before {
  font-family: "gotham-book";
}

.ql-font-gotham-light,
.ql-font span[data-value=gotham-light]::before {
  font-family: "gotham-light";
}

.ql-font-gotham-medium,
.ql-font span[data-value=gotham-medium]::before {
  font-family: "gotham-medium";
}

@font-face {
  font-family: "hind-light";
  src: url("../../fonts/hind/Hind-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "hind-regular";
  src: url("../../fonts/hind/Hind-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.ql-font-hindregular,
.ql-font span[data-value=hind-regular]::before {
  font-family: "hind-regular";
}

.ql-font-hindlight,
.ql-font span[data-value=hind-light]::before {
  font-family: "hind-light";
}

/* lato-regular - latin */
@font-face {
  font-family: "lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url("../../fonts/lato/lato-v13-latin-regular.woff2") format("woff2"), url("../../fonts/lato/lato-v13-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin */
@font-face {
  font-family: "lato-bold";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Bold"), local("Lato-Bold"), url("../../fonts/lato/lato-bold-webfont.woff2") format("woff2"), url("../../fonts/lato/lato-bold-webfont.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-lato,
.ql-font span[data-value=lato]::before {
  font-family: "lato";
}

.ql-font-lato-bold,
.ql-font span[data-value=lato-bold]::before {
  font-family: "lato-bold";
}

@font-face {
  font-family: "legacysans-book";
  src: url("../../fonts/legacy/LegacySansEF-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "legacysans-bookitalic";
  src: url("../../fonts/legacy/LegacySansEF-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "legacyserif-mediumitalic";
  src: url("../../fonts/legacy/LegacySerifEF-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "legacyserif-mediumSC";
  src: url("../../fonts/legacy/LegacySerifEF-MediumSC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.legacysans-book {
  font-family: "legacysans-book", sans-serif;
}

.legacysans-bookitalic {
  font-family: "legacysans-bookitalic", sans-serif;
}

.legacyserif-mediumitalic {
  font-family: "Legacy", sans-serif;
}

.legacyserif-mediumSC {
  font-family: "legacyserif-mediumSC", sans-serif;
}

@font-face {
  font-family: "lora-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/lora/Lora-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "lora-medium";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/lora/Lora-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "lora-semibold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/lora/Lora-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "lora-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/lora/Lora-Bold.ttf") format("truetype");
}
.ql-font-lora-regular,
.ql-font span[data-value=lora]::before {
  font-family: "lora-regular";
}

.ql-font-lora-medium,
.ql-font span[data-value=lora-light]::before {
  font-family: "lora-medium";
}

.ql-font-lora-semibold,
.ql-font span[data-value=lora-semibold]::before {
  font-family: "lora-semibold";
}

.ql-font-lora-bold,
.ql-font span[data-value=lora-bold]::before {
  font-family: "lora-bold";
}

/* merriweather-regular - latin */
@font-face {
  font-family: "merriweather-regular";
  font-style: normal;
  font-weight: 400;
  src: local("Merriweather"), local("Merriweather-Regular"), url("../../fonts/merriweather/merriweather-v17-latin-regular.woff2") format("woff2"), url("../../fonts/merriweather/merriweather-v17-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-light */
@font-face {
  font-family: "merriweather-light";
  font-style: normal;
  font-weight: 300;
  src: local("Merriweather Light"), local("Merriweather-Light"), url("../../fonts/merriweather/merriweather-light.woff2") format("woff2"), url("../../fonts/merriweather/merriweather-light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-bold */
@font-face {
  font-family: "merriweather-bold";
  font-style: normal;
  font-weight: 300;
  src: local("Merriweather Light"), local("Merriweather-Light"), url("../../fonts/merriweather/merriweather-bold.woff2") format("woff2"), url("../../fonts/merriweather/merriweather-bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-merriweather-regular,
.ql-font span[data-value=merriweather-regular]::before {
  font-family: "merriweather-regular";
}

.ql-font-merriweather-light,
.ql-font span[data-value=merriweather-light]::before {
  font-family: "merriweather-light";
}

.ql-font-merriweather-bold,
.ql-font span[data-value=merriweather-bold]::before {
  font-family: "merriweather-bold";
}

@font-face {
  font-family: "meta-pro-bold";
  font-weight: 700;
  font-style: normal;
  src: local("Meta"), local("Meta-Pro-Bold"), url("../../fonts/meta-pro/FFMetaProBold.woff2") format("woff2"), url("../../fonts/meta-pro/FFMetaProBold.woff") format("woff");
}
@font-face {
  font-family: "meta-pro";
  font-weight: 400;
  font-style: normal;
  src: local("Meta"), local("Meta-Pro-Book"), url("../../fonts/meta-pro/FFMetaProBook.woff2") format("woff2"), url("../../fonts/meta-pro/FFMetaProBook.woff") format("woff");
}
@font-face {
  font-family: "meta-pro-light";
  font-weight: 300;
  font-style: normal;
  src: local("Meta"), local("Meta-Pro-Light"), url("../../fonts/meta-pro/FFMetaProLight.woff2") format("woff2"), url("../../fonts/meta-pro/FFMetaProLight.woff") format("woff");
}
.ql-font-meta-pro,
.ql-font span[data-value=meta-pro]::before {
  font-family: "meta-pro";
}

.ql-font-meta-pro-bold,
.ql-font span[data-value=meta-pro-bold]::before {
  font-family: "meta-pro-bold";
}

.ql-font-meta-pro-light,
.ql-font span[data-value=meta-pro-light]::before {
  font-family: "meta-pro-light";
}

@font-face {
  font-family: "muli-bold";
  src: url("../../fonts/muli/Muli-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "muli-light";
  src: url("../../fonts/muli/Muli-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "muli-semi-bold";
  src: url("../../fonts/muli/Muli-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "muli";
  src: url("../../fonts/muli/Muli.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.ql-font-muli,
.ql-font span[data-value=muli]::before {
  font-family: "muli";
}

.ql-font-muli-bold,
.ql-font span[data-value=muli-bold]::before {
  font-family: "muli-bold";
}

.ql-font-muli-semi-bold,
.ql-font span[data-value=muli-semi-bold]::before {
  font-family: "muli-semi-bold";
}

.ql-font-muli-light,
.ql-font span[data-value=muli-light]::before {
  font-family: "muli-light";
}

@font-face {
  font-family: "myriad-pro";
  font-style: normal;
  font-weight: 400;
  src: local("Myriad"), local("Myriad-Pro"), url("../../fonts/myriad-pro/MyriadPro-Regular.woff2") format("woff2"), url("../../fonts/myriad-pro/MyriadPro-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "myriad-pro-bold";
  font-weight: 700;
  font-style: normal;
  src: local("Myriad"), local("Myriad-Pro-Bold"), url("../../fonts/myriad-pro/MyriadPro-Bold.woff2") format("woff2"), url("../../fonts/myriad-pro/MyriadPro-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "myriad-pro-light";
  font-weight: 300;
  font-style: normal;
  src: local("Myriad"), local("Myriad-Pro-Light"), url("../../fonts/myriad-pro/MyriadPro-Light.woff2") format("woff2"), url("../../fonts/myriad-pro/MyriadPro-Light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "myriad-pro-semibold";
  font-weight: 600;
  font-style: normal;
  src: local("Myriad"), local("Myriad-Pro-Semibold"), url("../../fonts/myriad-pro/MyriadPro-Semibold.woff2") format("woff2"), url("../../fonts/myriad-pro/MyriadPro-Semibold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-myriad-pro,
.ql-font span[data-value=myriad-pro]::before {
  font-family: "myriad-pro";
}

.ql-font-myriad-pro-bold,
.ql-font span[data-value=myriad-pro-bold]::before {
  font-family: "myriad-pro-bold";
}

.ql-font-myriad-pro-light,
.ql-font span[data-value=myriad-pro-light]::before {
  font-family: "myriad-pro-light";
}

.ql-font-myriad-pro-semibold,
.ql-font span[data-value=myriad-pro-semibold]::before {
  font-family: "myriad-pro-semibold";
}

/* neuton-regular - latin */
@font-face {
  font-family: "Neuton";
  font-style: normal;
  font-weight: 400;
  src: local("Neuton Regular"), local("Neuton-Regular"), url("../../fonts/neuton/neuton-v9-latin-regular.woff2") format("woff2"), url("../../fonts/neuton/neuton-v9-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.gfont_neuton_serif {
  font-family: "Neuton", sans-serif;
}

.gfont_neuton_serif {
  font-family: "Neuton", Sans-Serif;
}

@font-face {
  font-family: "open-sans-light";
  font-weight: 300;
  font-style: normal;
  src: local("Open Sans"), local("Open-Sans-Light"), url("../../fonts/open-sans/OpenSans-Light.woff2") format("woff2"), url("../../fonts/open-sans/OpenSans-Light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "open-sans-regular";
  font-weight: 400;
  font-style: normal;
  src: local("Open Sans"), local("Open-Sans-Regular"), url("../../fonts/open-sans/OpenSans-Regular.woff2") format("woff2"), url("../../fonts/open-sans/OpenSans-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "open-sans-medium";
  font-weight: 400;
  font-style: normal;
  src: local("Open Sans"), local("Open-Sans-Medium"), url("../../fonts/open-sans/OpenSans-Medium.woff2") format("woff2"), url("../../fonts/open-sans/OpenSans-Medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "open-sans-semi-bold";
  font-weight: 600;
  font-style: normal;
  src: local("Open Sans"), local("Open-Sans-Semibold"), url("../../fonts/open-sans/OpenSans-SemiBold.woff2") format("woff2"), url("../../fonts/open-sans/OpenSans-SemiBold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "open-sans-bold";
  font-weight: 600;
  font-style: normal;
  src: local("Open Sans"), local("Open-Sans-Bold"), url("../../fonts/open-sans/OpenSans-Bold.woff2") format("woff2"), url("../../fonts/open-sans/OpenSans-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-open-sans-regular,
.ql-font span[data-value=open-sans-regular]::before {
  font-family: "open-sans-regular", sans-serif;
}

.ql-font-open-sans-light,
.ql-font span[data-value=open-sans-light]::before {
  font-family: "open-sans-light", sans-serif;
}

.ql-font-open-sans-medium,
.ql-font span[data-value=open-sans-medium]::before {
  font-family: "open-sans-medium", sans-serif;
}

.ql-font-open-sans-semi-bold,
.ql-font span[data-value=open-sans-semi-bold]::before {
  font-family: "open-sans-semi-bold", sans-serif;
}

.ql-font-open-sans-bold,
.ql-font span[data-value=open-sans-bold]::before {
  font-family: "open-sans-bold", sans-serif;
}

@font-face {
  font-family: "optima";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/optima/optima.ttf") format("truetype");
}
@font-face {
  font-family: "optima-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/optima/optima_bold.ttf") format("truetype");
}
.ql-font-optima,
.ql-font span[data-value=optima]::before {
  font-family: "optima";
}

.ql-font-optima-bold,
.ql-font span[data-value=optima-bold]::before {
  font-family: "optima-bold";
}

@font-face {
  font-family: "pantograph-condensed-bold";
  font-style: normal;
  src: url("../../fonts/pantograph-condensed/pantograph-condensed-bold.otf") format("opentype");
}
.ql-font-pantograph-condensed-bold,
.ql-font span[data-value=pantograph-condensed-bold]::before {
  font-family: "pantograph-condensed-bold";
}

@font-face {
  font-family: "pantograph-extended-semibold";
  font-style: normal;
  src: url("../../fonts/pantograph-extended/pantograph-extended-semibold.otf") format("opentype");
}
.ql-font-pantograph-extended-semibold,
.ql-font span[data-value=pantograph-extended-semibold]::before {
  font-family: "pantograph-extended-semibold";
}

@font-face {
  font-family: "pantograph-wide-bold";
  font-style: normal;
  src: url("../../fonts/pantograph-wide/pantograph-wide-bold.otf") format("opentype");
}
@font-face {
  font-family: "pantograph-wide-regular";
  font-style: normal;
  src: url("../../fonts/pantograph-wide/pantograph-wide-regular.otf") format("opentype");
}
@font-face {
  font-family: "pantograph-wide-light";
  font-style: normal;
  src: url("../../fonts/pantograph-wide/pantograph-wide-light.otf") format("opentype");
}
.ql-font-pantograph-wide-bold,
.ql-font span[data-value=pantograph-wide-bold]::before {
  font-family: "pantograph-wide-bold";
}

.ql-font-pantograph-wide-regular,
.ql-font span[data-value=pantograph-wide-regular]::before {
  font-family: "pantograph-wide-regular";
}

.ql-font-pantograph-wide-light,
.ql-font span[data-value=pantograph-wide-light]::before {
  font-family: "pantograph-wide-light";
}

@font-face {
  font-family: "rector";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/rector/rector.ttf") format("truetype");
}
@font-face {
  font-family: "rector-medium";
  font-weight: 400;
  font-style: normal;
  src: local("Rector Medium"), url("../../fonts/rector/rector-medium.woff2") format("woff2");
}
.ql-font-rector,
.ql-font span[data-value=rector]::before {
  font-family: "rector";
}

.ql-font-rector-medium,
.ql-font span[data-value=rector-medium]::before {
  font-family: "rector-medium";
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../../fonts/roboto/roboto-regular.woff2") format("woff2"), url("../../fonts/roboto/roboto-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "roboto-light";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Light"), url("../../fonts/roboto/roboto-light.woff2") format("woff2"), url("../../fonts/roboto/roboto-light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "roboto-medium";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Medium"), url("../../fonts/roboto/roboto-medium.woff2") format("woff2"), url("../../fonts/roboto/roboto-medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "roboto-bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto"), local("Roboto-Bold"), url("../../fonts/roboto/roboto-bold.woff2") format("woff2"), url("../../fonts/roboto/roboto-bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-roboto,
.ql-font span[data-value=roboto]::before {
  font-family: "roboto";
}

.ql-font-roboto-light,
.ql-font span[data-value=roboto-light]::before {
  font-family: "roboto-light";
}

.ql-font-roboto-medium,
.ql-font span[data-value=roboto-medium]::before {
  font-family: "roboto-medium";
}

.ql-font-robotobold,
.ql-font span[data-value=robotobold]::before {
  font-family: "roboto-bold";
}

@font-face {
  font-family: "robotoslabregular";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto-Slab-Regular"), local("Roboto-Slab-Regular"), url("../../fonts/roboto-slab/RobotoSlab-Regular.woff2") format("woff2"), url("../../fonts/roboto-slab/RobotoSlab-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-robotoslabregular,
.ql-font span[data-value=robotoslabregular]::before {
  font-family: "robotoslabregular";
}

@font-face {
  font-family: "rubik-light";
  font-weight: 300;
  font-style: normal;
  src: local("Rubik"), local("Rubik-Light"), url("../../fonts/rubik/Rubik-Light.woff2") format("woff2"), url("../../fonts/rubik/Rubik-Light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "rubik-regular";
  font-weight: 400;
  font-style: normal;
  src: local("Rubik"), local("Rubik-Regular"), url("../../fonts/rubik/Rubik-Regular.woff2") format("woff2"), url("../../fonts/rubik/Rubik-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "rubik-medium";
  font-weight: 400;
  font-style: normal;
  src: local("Rubik"), local("Rubik-Medium"), url("../../fonts/rubik/Rubik-Medium.woff2") format("woff2"), url("../../fonts/rubik/Rubik-Medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "rubik-semi-bold";
  font-weight: 600;
  font-style: normal;
  src: local("Rubik"), local("Rubik-Semibold"), url("../../fonts/rubik/Rubik-SemiBold.woff2") format("woff2"), url("../../fonts/rubik/Rubik-SemiBold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "rubik-bold";
  font-weight: 600;
  font-style: normal;
  src: local("Rubik"), local("Rubik-Bold"), url("../../fonts/rubik/Rubik-Bold.woff2") format("woff2"), url("../../fonts/rubik/Rubik-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.ql-font-rubik-light,
.ql-font span[data-value=rubik-light]::before {
  font-family: "rubik-light";
}

.ql-font-rubik-regular,
.ql-font span[data-value=rubik-regular]::before {
  font-family: "rubik-regular";
}

.ql-font-rubik-medium,
.ql-font span[data-value=rubik-medium]::before {
  font-family: "rubik-medium";
}

.ql-font-rubik-semi-bold,
.ql-font span[data-value=rubik-semi-bold]::before {
  font-family: "rubik-semi-bold";
}

.ql-font-rubik-bold,
.ql-font span[data-value=rubik-bold]::before {
  font-family: "rubik-bold";
}

@font-face {
  font-family: "verdana-bold";
  font-style: normal;
  src: url("../../fonts/verdana/verdana-bold.ttf") format("truetype");
}
@font-face {
  font-family: "verdana-regular";
  font-style: normal;
  src: url("../../fonts/verdana/verdana.ttf") format("truetype");
}
.ql-font-verdana-bold,
.ql-font span[data-value=verdana-bold]::before {
  font-family: "verdana-bold";
}

.ql-font-verdana-regular,
.ql-font span[data-value=verdana-regular]::before {
  font-family: "verdana-regular";
}

@font-face {
  font-family: "whitney-bold";
  src: url("../../fonts/whitney/Whitney-Bold-Pro.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "whitney-book";
  src: url("../../fonts/whitney/Whitney-Book-Pro.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "whitney-light";
  src: url("../../fonts/whitney/Whitney-Light-Pro.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "whitney-semibold";
  src: url("../../fonts/whitney/Whitney-Semi-Bold-Pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.ql-font-whitney-bold,
.ql-font span[data-value=whitney-bold]::before {
  font-family: "whitney-bold";
}

.ql-font-whitney-light,
.ql-font span[data-value=whitney-light]::before {
  font-family: "whitney-light";
}

.ql-font-whitney-book,
.ql-font span[data-value=whitney-book]::before {
  font-family: "whitney-book";
}

.ql-font-whitney-semibold,
.ql-font span[data-value=whitney-semibold]::before {
  font-family: "whitney-semibold";
}

@font-face {
  font-family: "zapfino-lino-type-one";
  src: url("../../fonts/zapfino/zapfinolinotypeone.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.gfont_zapfino {
  font-family: "zapfino-lino-type-one", Sans-Serif;
}

@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/font-awesome/fontawesome-pro-solid-900.eot");
  src: url("../../fonts/font-awesome/fontawesome-pro-solid-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/font-awesome/fontawesome-pro-solid-900.woff2") format("woff2"), url("../../fonts/font-awesome/fontawesome-pro-solid-900.woff") format("woff"), url("../../fonts/font-awesome/fontawesome-pro-solid-900.ttf") format("truetype"), url("../../fonts/font-awesome/fontawesome-pro-solid-900.svg#fontawesome") format("svg");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Solid";
  font-weight: 900;
}

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 1.875em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -1.875em;
  position: absolute;
  text-align: center;
  top: 0.1428571429em;
  width: 1.875em;
}
.fa-li.fa-lg {
  left: -1.625em;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-alarm-clock:before {
  content: "\f34e";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-archive:before {
  content: "\f187";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-alt-down:before {
  content: "\f354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}

.fa-arrow-alt-from-left:before {
  content: "\f347";
}

.fa-arrow-alt-from-right:before {
  content: "\f348";
}

.fa-arrow-alt-from-top:before {
  content: "\f349";
}

.fa-arrow-alt-left:before {
  content: "\f355";
}

.fa-arrow-alt-right:before {
  content: "\f356";
}

.fa-arrow-alt-square-down:before {
  content: "\f350";
}

.fa-arrow-alt-square-left:before {
  content: "\f351";
}

.fa-arrow-alt-square-right:before {
  content: "\f352";
}

.fa-arrow-alt-square-up:before {
  content: "\f353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}

.fa-arrow-alt-to-left:before {
  content: "\f34b";
}

.fa-arrow-alt-to-right:before {
  content: "\f34c";
}

.fa-arrow-alt-to-top:before {
  content: "\f34d";
}

.fa-arrow-alt-up:before {
  content: "\f357";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-from-bottom:before {
  content: "\f342";
}

.fa-arrow-from-left:before {
  content: "\f343";
}

.fa-arrow-from-right:before {
  content: "\f344";
}

.fa-arrow-from-top:before {
  content: "\f345";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-square-down:before {
  content: "\f339";
}

.fa-arrow-square-left:before {
  content: "\f33a";
}

.fa-arrow-square-right:before {
  content: "\f33b";
}

.fa-arrow-square-up:before {
  content: "\f33c";
}

.fa-arrow-to-bottom:before {
  content: "\f33d";
}

.fa-arrow-to-left:before {
  content: "\f33e";
}

.fa-arrow-to-right:before {
  content: "\f340";
}

.fa-arrow-to-top:before {
  content: "\f341";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aws:before {
  content: "\f375";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-badge:before {
  content: "\f335";
}

.fa-badge-check:before {
  content: "\f336";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-bolt:before {
  content: "\f376";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-slash:before {
  content: "\f377";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-browser:before {
  content: "\f37e";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-edit:before {
  content: "\f333";
}

.fa-calendar-exclamation:before {
  content: "\f334";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-alt:before {
  content: "\f332";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}

.fa-caret-circle-left:before {
  content: "\f32e";
}

.fa-caret-circle-right:before {
  content: "\f330";
}

.fa-caret-circle-up:before {
  content: "\f331";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-double-down:before {
  content: "\f322";
}

.fa-chevron-double-left:before {
  content: "\f323";
}

.fa-chevron-double-right:before {
  content: "\f324";
}

.fa-chevron-double-up:before {
  content: "\f325";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-square-down:before {
  content: "\f329";
}

.fa-chevron-square-left:before {
  content: "\f32a";
}

.fa-chevron-square-right:before {
  content: "\f32b";
}

.fa-chevron-square-up:before {
  content: "\f32c";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-club:before {
  content: "\f327";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-code-commit:before {
  content: "\f386";
}

.fa-code-merge:before {
  content: "\f387";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comments:before {
  content: "\f086";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-wide:before {
  content: "\f326";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-credit-card-blank:before {
  content: "\f389";
}

.fa-credit-card-front:before {
  content: "\f38a";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-desktop-alt:before {
  content: "\f390";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-dropbox-alt:before {
  content: "\f398";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-h-alt:before {
  content: "\f39b";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ellipsis-v-alt:before {
  content: "\f39c";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-square:before {
  content: "\f321";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows:before {
  content: "\f31d";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expand-wide:before {
  content: "\f320";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-check:before {
  content: "\f316";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-edit:before {
  content: "\f31c";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-exclamation:before {
  content: "\f31a";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-minus:before {
  content: "\f318";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-plus:before {
  content: "\f319";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-times:before {
  content: "\f317";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-film:before {
  content: "\f008";
}

.fa-film-alt:before {
  content: "\f3a0";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frown:before {
  content: "\f119";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-h1:before {
  content: "\f313";
}

.fa-h2:before {
  content: "\f314";
}

.fa-h3:before {
  content: "\f315";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-hexagon:before {
  content: "\f312";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-home:before {
  content: "\f015";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-inbox-in:before {
  content: "\f310";
}

.fa-inbox-out:before {
  content: "\f311";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-industry-alt:before {
  content: "\f3b3";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-info-square:before {
  content: "\f30f";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-jack-o-lantern:before {
  content: "\f30e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-key:before {
  content: "\f084";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-alt:before {
  content: "\f30d";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-lock-open-alt:before {
  content: "\f3c2";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-male:before {
  content: "\f183";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-hexagon:before {
  content: "\f307";
}

.fa-minus-octagon:before {
  content: "\f308";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-mobile-android:before {
  content: "\f3ce";
}

.fa-mobile-android-alt:before {
  content: "\f3cf";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-moon:before {
  content: "\f186";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nintendo-switch:before {
  content: "\f3d3";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-octagon:before {
  content: "\f306";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-percent:before {
  content: "\f295";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-alt:before {
  content: "\f3de";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-hexagon:before {
  content: "\f300";
}

.fa-plus-octagon:before {
  content: "\f301";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-print:before {
  content: "\f02f";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-question-square:before {
  content: "\f2fd";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-random:before {
  content: "\f074";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-rectangle-landscape:before {
  content: "\f2fa";
}

.fa-rectangle-portrait:before {
  content: "\f2fb";
}

.fa-rectangle-wide:before {
  content: "\f2fc";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-rendact:before {
  content: "\f3e4";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-repeat:before {
  content: "\f363";
}

.fa-repeat-1:before {
  content: "\f365";
}

.fa-repeat-1-alt:before {
  content: "\f366";
}

.fa-repeat-alt:before {
  content: "\f364";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-retweet-alt:before {
  content: "\f361";
}

.fa-road:before {
  content: "\f018";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-safari:before {
  content: "\f267";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scrubber:before {
  content: "\f2f8";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-all:before {
  content: "\f367";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield:before {
  content: "\f132";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-shield-check:before {
  content: "\f2f7";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-sliders-h-square:before {
  content: "\f3f0";
}

.fa-sliders-v:before {
  content: "\f3f1";
}

.fa-sliders-v-square:before {
  content: "\f3f2";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-spade:before {
  content: "\f2f4";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-exclamation:before {
  content: "\f2f3";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablet-android:before {
  content: "\f3fb";
}

.fa-tablet-android-alt:before {
  content: "\f3fc";
}

.fa-tachometer:before {
  content: "\f0e4";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-times-hexagon:before {
  content: "\f2ee";
}

.fa-times-octagon:before {
  content: "\f2f0";
}

.fa-times-square:before {
  content: "\f2d3";
}

.fa-tint:before {
  content: "\f043";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-train:before {
  content: "\f238";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-tree-alt:before {
  content: "\f400";
}

.fa-trello:before {
  content: "\f181";
}

.fa-triangle:before {
  content: "\f2ec";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-tv-retro:before {
  content: "\f401";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-uber:before {
  content: "\f402";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usd-circle:before {
  content: "\f2e8";
}

.fa-usd-square:before {
  content: "\f2e9";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-fork:before {
  content: "\f2e3";
}

.fa-utensil-knife:before {
  content: "\f2e4";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-utensils-alt:before {
  content: "\f2e6";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f2e2";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-watch:before {
  content: "\f2e1";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-window:before {
  content: "\f40e";
}

.fa-window-alt:before {
  content: "\f40f";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.text-editor-wysiwyg .ql-editor p > br {
  content: "";
  font-size: 10em !important;
  display: block;
}

.text-editor-wysiwyg .ql-editor p > br::after {
  content: " ";
}

.text-editor-wysiwyg .ql-editor {
  font-size: 1.08px !important;
}

.text-editor-wysiwyg-dark .ql-editor p > br {
  font-size: 10em !important;
}

.text-editor-wysiwyg-dark .ql-editor {
  font-size: 1.08px !important;
  background-color: #1e1d1d;
}

.text-editor-wysiwyg-dark-px .ql-editor {
  background-color: #1e1d1d;
}

div:not(.text-editor-wysiwyg-dark-px) > div > .quill > .ql-container > .ql-editor {
  background-color: #e3e3e3;
}

.ql-editor-preview .ql-editor {
  background-color: transparent !important;
  overflow-y: hidden;
}

.text-editor-wysiwyg-panel .ql-editor {
  max-height: 50vh;
}

.ql-editor ol li::before {
  font-size: 12px;
}

.ql-editor ul li::before {
  font-size: 12px;
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 20em;
}

.ql-snow .ql-picker.ql-font, .ql-snow .ql-picker.ql-fontfamily {
  width: 100px;
}

.ql-fontfamily .ql-picker-label:before {
  content: attr(data-label);
}

.ql-fontfamily .ql-picker-item:before {
  content: attr(data-label);
}

.ql-picker-options {
  max-height: 250px;
  overflow-y: scroll;
}

.ql-size-1em {
  font-size: 1em;
}

.ql-size-2em {
  font-size: 2em;
}

.ql-size-3em {
  font-size: 3em;
}

.ql-size-4em {
  font-size: 4em;
}

.ql-size-5em {
  font-size: 5em;
}

.ql-size-6em {
  font-size: 6em;
}

.ql-size-7em {
  font-size: 7em;
}

.ql-size-8em {
  font-size: 8em;
}

.ql-size-9em {
  font-size: 9em;
}

.ql-size-10em {
  font-size: 10em;
}

.ql-size-11em {
  font-size: 11em;
}

.ql-size-12em {
  font-size: 12em;
}

.ql-size-13em {
  font-size: 13em;
}

.ql-size-14em {
  font-size: 14em;
}

.ql-size-15em {
  font-size: 15em;
}

.ql-size-16em {
  font-size: 16em;
}

.ql-size-17em {
  font-size: 17em;
}

.ql-size-18em {
  font-size: 18em;
}

.ql-size-19em {
  font-size: 19em;
}

.ql-size-20em {
  font-size: 20em;
}

.ql-size-21em {
  font-size: 21em;
}

.ql-size-22em {
  font-size: 22em;
}

.ql-size-23em {
  font-size: 23em;
}

.ql-size-24em {
  font-size: 24em;
}

.ql-size-25em {
  font-size: 25em;
}

.ql-size-26em {
  font-size: 26em;
}

.ql-size-27em {
  font-size: 27em;
}

.ql-size-28em {
  font-size: 28em;
}

.ql-size-29em {
  font-size: 29em;
}

.ql-size-30em {
  font-size: 30em;
}

.ql-size-31em {
  font-size: 31em;
}

.ql-size-32em {
  font-size: 32em;
}

.ql-size-33em {
  font-size: 33em;
}

.ql-size-34em {
  font-size: 34em;
}

.ql-size-35em {
  font-size: 35em;
}

.ql-size-36em {
  font-size: 36em;
}

.ql-size-37em {
  font-size: 37em;
}

.ql-size-38em {
  font-size: 38em;
}

.ql-size-39em {
  font-size: 39em;
}

.ql-size-40em {
  font-size: 40em;
}

.ql-size-41em {
  font-size: 41em;
}

.ql-size-42em {
  font-size: 42em;
}

.ql-size-43em {
  font-size: 43em;
}

.ql-size-44em {
  font-size: 44em;
}

.ql-size-45em {
  font-size: 45em;
}

.ql-size-46em {
  font-size: 46em;
}

.ql-size-47em {
  font-size: 47em;
}

.ql-size-48em {
  font-size: 48em;
}

.ql-size-49em {
  font-size: 49em;
}

.ql-size-50em {
  font-size: 50em;
}

.ql-size-51em {
  font-size: 51em;
}

.ql-size-52em {
  font-size: 52em;
}

.ql-size-53em {
  font-size: 53em;
}

.ql-size-54em {
  font-size: 54em;
}

.ql-size-55em {
  font-size: 55em;
}

.ql-size-56em {
  font-size: 56em;
}

.ql-size-57em {
  font-size: 57em;
}

.ql-size-58em {
  font-size: 58em;
}

.ql-size-59em {
  font-size: 59em;
}

.ql-size-60em {
  font-size: 60em;
}

.ql-size-61em {
  font-size: 61em;
}

.ql-size-62em {
  font-size: 62em;
}

.ql-size-63em {
  font-size: 63em;
}

.ql-size-64em {
  font-size: 64em;
}

.ql-size-65em {
  font-size: 65em;
}

.ql-size-66em {
  font-size: 66em;
}

.ql-size-67em {
  font-size: 67em;
}

.ql-size-68em {
  font-size: 68em;
}

.ql-size-69em {
  font-size: 69em;
}

.ql-size-70em {
  font-size: 70em;
}

.ql-size-71em {
  font-size: 71em;
}

.ql-size-72em {
  font-size: 72em;
}

.ql-size-73em {
  font-size: 73em;
}

.ql-size-74em {
  font-size: 74em;
}

.ql-size-75em {
  font-size: 75em;
}

.ql-size-76em {
  font-size: 76em;
}

.ql-size-77em {
  font-size: 77em;
}

.ql-size-78em {
  font-size: 78em;
}

.ql-size-79em {
  font-size: 79em;
}

.ql-size-80em {
  font-size: 80em;
}

.ql-size-81em {
  font-size: 81em;
}

.ql-size-82em {
  font-size: 82em;
}

.ql-size-83em {
  font-size: 83em;
}

.ql-size-84em {
  font-size: 84em;
}

.ql-size-85em {
  font-size: 85em;
}

.ql-size-86em {
  font-size: 86em;
}

.ql-size-87em {
  font-size: 87em;
}

.ql-size-88em {
  font-size: 88em;
}

.ql-size-89em {
  font-size: 89em;
}

.ql-size-90em {
  font-size: 90em;
}

.ql-size-91em {
  font-size: 91em;
}

.ql-size-92em {
  font-size: 92em;
}

.ql-size-93em {
  font-size: 93em;
}

.ql-size-94em {
  font-size: 94em;
}

.ql-size-95em {
  font-size: 95em;
}

.ql-size-96em {
  font-size: 96em;
}

.ql-size-97em {
  font-size: 97em;
}

.ql-size-98em {
  font-size: 98em;
}

.ql-size-99em {
  font-size: 99em;
}

.ql-size-100em {
  font-size: 100em;
}

.ql-size-8px {
  font-size: 0.692vw;
}

.ql-size-10px {
  font-size: 0.865vw;
}

.ql-size-12px {
  font-size: 1.041vw;
}

.ql-size-16px {
  font-size: 1.384vw;
}

.ql-size-18px {
  font-size: 1.557vw;
}

.ql-size-20px {
  font-size: 1.73vw;
}

.ql-size-22px {
  font-size: 1.903vw;
}

.ql-size-24px {
  font-size: 2.08vw;
}

.ql-size-26px {
  font-size: 2.249vw;
}

.ql-size-28px {
  font-size: 2.422vw;
}

.ql-size-30px {
  font-size: 2.595vw;
}

.ql-size-32px {
  font-size: 2.768vw;
}

.ql-size-34px {
  font-size: 2.941vw;
}

.ql-size-36px {
  font-size: 3.114vw;
}

.ql-size-38px {
  font-size: 3.287vw;
}

.ql-size-48px {
  font-size: 4.152vw;
}

.ql-size-60px {
  font-size: 5.19vw;
}

.ql-size-70px {
  font-size: 6.055vw;
}

.ql-size-125px {
  font-size: 10.8125vw;
}

.ql-font-serif {
  font-family: serif;
}

.ql-font-sansserif {
  font-family: sans-serif;
}

.ql-editor blockquote {
  /* background: #f9f9f9; */
  border-left: 1vw solid #ccc;
  margin: 1.5em 10px;
  padding: 1vw !important;
  quotes: "“" "”" "‘" "’";
}

.ql-editor-preview .ql-editor br {
  content: "";
  display: block;
}

.ql-editor p:has(+ br) {
  font-size: 20em;
}

.ql-editor-preview .ql-editor br::after {
  content: " ";
  font-size: 10em;
}

.horizontal-screen-any .ql-size-8px {
  font-size: 0.74074vh;
}
.horizontal-screen-any .ql-size-10px {
  font-size: 0.9255vh;
}
.horizontal-screen-any .ql-size-12px {
  font-size: 1.1106vh;
}
.horizontal-screen-any .ql-size-16px {
  font-size: 1.4808vh;
}
.horizontal-screen-any .ql-size-18px {
  font-size: 1.6666vh;
}
.horizontal-screen-any .ql-size-20px {
  font-size: 1.8511vh;
}
.horizontal-screen-any .ql-size-22px {
  font-size: 2.0362vh;
}
.horizontal-screen-any .ql-size-24px {
  font-size: 2.2213vh;
}
.horizontal-screen-any .ql-size-26px {
  font-size: 2.407vh;
}
.horizontal-screen-any .ql-size-28px {
  font-size: 2.592vh;
}
.horizontal-screen-any .ql-size-30px {
  font-size: 2.7766vh;
}
.horizontal-screen-any .ql-size-32px {
  font-size: 2.962vh;
}
.horizontal-screen-any .ql-size-34px {
  font-size: 3.148vh;
}
.horizontal-screen-any .ql-size-36px {
  font-size: 3.332vh;
}
.horizontal-screen-any .ql-size-38px {
  font-size: 3.519vh;
}
.horizontal-screen-any .ql-size-48px {
  font-size: 4.4426vh;
}
.horizontal-screen-any .ql-size-54px {
  font-size: 4.998vh;
}
.horizontal-screen-any .ql-size-60px {
  font-size: 5.5533vh;
}
.horizontal-screen-any .ql-size-70px {
  font-size: 6.4788vh;
}
.horizontal-screen-any .ql-size-125px {
  font-size: 11.5694vh;
}

.horizontal-screen-x2 .ql-size-8px {
  font-size: 0.692vw;
}
.horizontal-screen-x2 .ql-size-10px {
  font-size: 0.865vw;
}
.horizontal-screen-x2 .ql-size-12px {
  font-size: 1.041vw;
}
.horizontal-screen-x2 .ql-size-16px {
  font-size: 1.384vw;
}
.horizontal-screen-x2 .ql-size-18px {
  font-size: 1.557vw;
}
.horizontal-screen-x2 .ql-size-20px {
  font-size: 1.73vw;
}
.horizontal-screen-x2 .ql-size-22px {
  font-size: 0.9515vw;
}
.horizontal-screen-x2 .ql-size-24px {
  font-size: 2.08vw;
}
.horizontal-screen-x2 .ql-size-30px {
  font-size: 2.595vw;
}
.horizontal-screen-x2 .ql-size-36px {
  font-size: 3.114vw;
}
.horizontal-screen-x2 .ql-size-38px {
  font-size: 3.287vw;
}
.horizontal-screen-x2 .ql-size-48px {
  font-size: 4.152vw;
}
.horizontal-screen-x2 .ql-size-60px {
  font-size: 5.19vw;
}
.horizontal-screen-x2 .ql-size-70px {
  font-size: 6.055vw;
}
.horizontal-screen-x2 .ql-size-125px {
  font-size: 10.8125vw;
}

.vertical-screen-x1 .ql-size-8px {
  font-size: 0.416vh;
}
.vertical-screen-x1 .ql-size-10px {
  font-size: 0.52vh;
}
.vertical-screen-x1 .ql-size-12px {
  font-size: 0.624vh;
}
.vertical-screen-x1 .ql-size-16px {
  font-size: 0.832vh;
}
.vertical-screen-x1 .ql-size-18px {
  font-size: 0.936vh;
}
.vertical-screen-x1 .ql-size-20px {
  font-size: 1.04vh;
}
.vertical-screen-x1 .ql-size-22px {
  font-size: 1.144vh;
}
.vertical-screen-x1 .ql-size-24px {
  font-size: 1.248vh;
}
.vertical-screen-x1 .ql-size-30px {
  font-size: 1.56vh;
}
.vertical-screen-x1 .ql-size-36px {
  font-size: 1.872vh;
}
.vertical-screen-x1 .ql-size-38px {
  font-size: 1.973vh;
}
.vertical-screen-x1 .ql-size-48px {
  font-size: 2.496vh;
}
.vertical-screen-x1 .ql-size-54px {
  font-size: 2.808vh;
}
.vertical-screen-x1 .ql-size-60px {
  font-size: 3.12vh;
}
.vertical-screen-x1 .ql-size-70px {
  font-size: 3.64vh;
}
.vertical-screen-x1 .ql-size-125px {
  font-size: 6.5vh;
}

.denver-stories__container {
  height: 100vh;
  width: 100vw;
  position: absolute;
}
.denver-stories__content {
  height: 100vh;
  width: 100vw;
  z-index: 10;
  position: absolute;
}
.denver-stories__heading {
  padding: 0;
  margin: 0;
}
.denver-stories__main-copy {
  padding: 0 6vw 0 6vw;
}
.denver-stories__image {
  width: 46vw;
}
.denver-stories__quote {
  line-height: 1.75;
  margin: 0;
  padding-top: 3vh;
  padding-bottom: 1vh;
}
.denver-stories__quote-footer {
  padding: 1.5vh 0;
}
.denver-stories__two-cols {
  display: flex;
  margin: 3vh 0;
  justify-content: space-between;
}
.denver-stories__centered-col {
  display: flex;
  margin: 3vh 0;
  justify-content: center;
}
.denver-stories__col {
  flex: 0 0 48%;
}
.denver-stories__col:nth-child(2) {
  margin-left: 3.5vw;
}
.denver-stories__image-container {
  margin: 0;
  width: inherit;
  text-align: center;
}
.denver-stories__image-container-background {
  background: #abb3a7;
  opacity: 0.5;
  width: 38.51vw;
  height: 34.72vw;
  position: absolute;
}
.denver-stories__image-container-table {
  width: 38.51vw;
  height: 34.72vw;
  display: table;
}
.denver-stories__image-container-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.denver-stories__image-container-table-cell > img {
  position: relative !important;
  max-height: 34.72vw !important;
  max-width: 38.51vw !important;
}
.denver-stories__image-container-image {
  margin: 0;
  position: relative !important;
  max-height: 100% !important;
  max-width: 100%;
}
.denver-stories__image-container-caption {
  margin: 0;
  font-size: 0.9vh;
  font-family: "Roboto Medium";
  text-align: left;
  padding: 0.8vh 0;
}
.denver-stories__subhead, .denver-stories__body {
  margin-bottom: 2vh;
  padding-right: 3vw;
}
.denver-stories__story-1 {
  position: relative;
  text-align: center;
  padding: 7vh 7vw;
}
.denver-stories__story-2, .denver-stories__story-3, .denver-stories__story-4 {
  position: relative;
  padding: 3vh 7vw 0 9vw;
}

.denver-donor-list {
  font-family: "Roboto Medium";
}
.denver-donor-list__description-container > span > p:first-of-type {
  margin: 0;
}

.fullEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-margin p {
  margin: 0;
}

.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-fade-exit {
  opacity: 1;
}

.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.transition-dissolve-enter {
  opacity: 1;
}

.transition-dissolve-enter-active {
  opacity: 1;
}

.transition-dissolve-exit {
  opacity: 1;
}

.transition-dissolve-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 1250ms;
}

.transition-fade500ms1s-enter {
  opacity: 0;
}

.transition-fade500ms1s-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-fade500ms1s-exit {
  opacity: 1;
}

.transition-fade500ms1s-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.transition-fade250ms-enter {
  opacity: 0;
}

.transition-fade250ms-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.transition-fade250ms-exit {
  opacity: 1;
}

.transition-fade250ms-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.transition-fade1s-enter {
  opacity: 0;
}

.transition-fade1s-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.transition-fade1s-exit {
  opacity: 1;
}

.transition-fade1s-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.transition-fadeout-enter {
  opacity: 1;
}

.transition-fadeout-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-fadeout-exit {
  opacity: 1;
}

.transition-fadeout-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.transition-slowinfastout-enter {
  opacity: 0;
}

.transition-slowinfastout-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-slowinfastout-exit {
  opacity: 1;
}

.transition-slowinfastout-exit-active {
  opacity: 0;
  transition: opacity 10ms ease-in;
}

.transition-slide-in-from-right-enter {
  z-index: 999;
  left: 100%;
  position: absolute;
}

.transition-fastinslowout-enter {
  opacity: 1;
}

.transition-fastinslowout-enter-active {
  opacity: 1;
  transition: opacity 10ms ease-in;
}

.transition-fastinslowout-exit {
  opacity: 1;
}

.transition-fastinslowout-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.transition-slide-in-from-right-enter {
  z-index: 999;
  left: 100%;
  position: absolute;
}

.transition-slide-in-from-right-enter-active {
  z-index: 999;
  left: 0%;
  transition: left linear 1s;
}

.transition-slide-in-from-right-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-right-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

.transition-slide-in-from-left-enter {
  z-index: 999;
  left: -100%;
  position: absolute;
}

.transition-slide-in-from-left-enter-active {
  z-index: 999;
  left: 0%;
  transition: left linear 1s;
}

.transition-slide-in-from-left-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-left-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

.transition-slide-in-from-top-enter {
  z-index: 999;
  top: -100%;
  position: absolute;
}

.transition-slide-in-from-top-enter-active {
  z-index: 999;
  top: 0%;
  transition: top linear 500ms;
}

.transition-slide-in-from-top-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-top-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

.transition-slide-in-from-bottom-enter {
  z-index: 999;
  top: 100%;
  position: absolute;
}

.transition-slide-in-from-bottom-enter-active {
  z-index: 999;
  top: 0%;
  transition: top linear 500ms;
}

.transition-slide-in-from-bottom-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-bottom-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

.transition-fade-2s-enter {
  opacity: 0;
}

.transition-fade-2s-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.transition-fade-2s-exit {
  opacity: 1;
}

.transition-fade-2s-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

.transition-fadeout-2s-enter {
  opacity: 1;
}

.transition-fadeout-2s-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.transition-fadeout-2s-exit {
  opacity: 1;
}

.transition-fadeout-2s-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

.transition-fade-2s-linear-enter {
  opacity: 0;
}

.transition-fade-2s-linear-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.transition-fade-2s-linear-exit {
  opacity: 1;
}

.transition-fade-2s-linear-exit-active {
  opacity: 0;
  transition: opacity 2000ms linear;
}

.float-right {
  float: right;
}

.cursor-main {
  cursor: pointer;
}

.phand {
  cursor: pointer;
}

.outerC {
  display: table;
  width: 100%;
}

.middleC {
  display: table-cell;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

.innerC {
  width: 80vw;
  margin: auto;
}

.nodrag {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.backdropOn {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0.4;
  background-color: black;
  transition: opacity 300ms linear;
  pointer-events: auto;
  width: 100vw;
  height: 100vh;
}

.backdropOff {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: black;
  opacity: 0;
  transition: opacity 300ms linear;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
}

#slideout_background {
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #333;
  position: fixed;
  top: 0px;
  left: -30vw;
  width: 30vw;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

#slideout {
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #333;
  position: fixed;
  top: 0px;
  left: -30vw;
  width: 30vw;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

#slideout {
  display: block;
  height: 100vh;
}

#slideout.on {
  left: 0;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aligner-item--top {
  align-self: flex-start;
}

.aligner-item--bottom {
  align-self: flex-end;
}

.valigner {
  display: flex;
  align-items: center;
}

.valigner-item--top {
  align-self: flex-start;
}

.valigner-item--bottom {
  align-self: flex-end;
}

.fadediv.fadeout {
  opacity: 0;
}

.fadediv {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

.jigglewrapper {
  left: 50%;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.jiggleitem {
  -webkit-animation: jiggle 7s infinite;
  -moz-animation: jiggle 7s infinite;
  -o-animation: jiggle 7s infinite;
  animation: jiggle 7s infinite;
}

.bounceitem {
  -webkit-animation: bounce 3s linear 3s infinite normal running;
  -moz-animation: bounce 3s linear 3s infinite normal running;
  -o-animation: bounce 3s linear 3s infinite normal running;
  animation: bounce 3s linear 3s infinite normal running;
}

.velem {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.helem {
  text-align: center;
}

.cScale {
  transition: font-size 250ms ease-in;
}

.cScale.active {
  transition: font-size 250ms ease-in;
}

.cScale:hover {
  transition: font-size 250ms ease-in;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currentlysupported by Chrome and Opera */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.pnormal {
  cursor: pointer;
}

.threecolumn {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
  -webkit-column-gap: 5vh; /* Chrome, Safari, Opera */
  -moz-column-gap: 5vh; /* Firefox */
  column-gap: 5vh;
}

.keystd {
  width: 30px;
  height: 30px;
  margin: 10px;
  display: inline-block;
  background-color: green;
  text-align: center;
  -webkit-backface-visibility: hidden;
}

.kspn {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  color: #95268E;
  font-family: "open-sans-bold";
}

.kspn-primary {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  color: black;
  font-family: "open-sans-bold";
}

.kspn-seattle {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  color: #000;
  font-family: "Lato";
}

.kspn-tampa {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  color: #143e77;
  font-family: "open-sans-bold";
}

.pressin {
  transition: transform 0.125s ease-in-out;
  transform-origin: 50% 50%;
}

.pressin.active {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.keyopacity {
  transition: opacity 0.3s;
  opacity: 1;
}

.keyopacity.keyhide {
  transition: opacity 0.3s;
  opacity: 0;
}

.rotate90 {
  transform: rotate(-90deg);
  transform-origin: 100% 0;
  position: absolute;
}

.slideIn {
  max-width: 0;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  overflow-x: hidden;
  white-space: nowrap;
}

.slideOut {
  max-width: 50em;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  overflow-x: hidden;
  white-space: nowrap;
}

.sliding-container {
  position: relative;
  display: block;
  height: 50px;
  overflow: hidden;
  text-align: center;
}

.sliding {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: 0;
  position: absolute;
}

.sliding-enter {
  transform: translateX(100%);
  transition: 0.3s transform ease-in-out;
}

.sliding-enter.sliding-enter-active {
  transform: translateX(0);
}

.sliding-leave {
  transform: translateX(0);
  transition: 0.3s transform ease-in-out;
}

.sliding-leave.sliding-leave-active {
  transform: translateX(-100%);
}

.sliding-appear {
  transform: translateX(100%);
  transition: 0.3s transform ease-in-out;
}

.sliding-appear.sliding-appear-active {
  transform: translateX(0);
}

.whiteSpaceAndBreaks {
  white-space: pre-wrap;
}

.fliph {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.leftToRight {
  left: 100%;
  position: absolute;
  -webkit-animation: linear infinite normal;
  -webkit-animation-name: run;
  -webkit-animation-duration: 6s;
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }
  100% {
    left: 200%;
  }
}
.opacity0 {
  opacity: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  text-shadow: 0 0 48px #ffffff;
}

.opacity0small {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  text-shadow: 0 0 48px #ffffff;
}

.fadeablein {
  -webkit-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -moz-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -o-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.fadeableout {
  -webkit-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -moz-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -o-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  text-shadow: 0 0 48px #ffffff;
}

.backgroundGradiant {
  background: "red"; /* For browsers that do not support gradients */
  background: "-webkit-linear-gradient(-35deg, #923799, #0C909A)"; /* For Safari 5.1 to 6.0 */
  background: "-o-linear-gradient(-35deg, #923799, #0C909A)"; /* For Opera 11.1 to 12.0 */
  background: "-moz-linear-gradient(-35deg, #923799, #0C909A)"; /* For Firefox 3.6 to 15 */
  background: "linear-gradient(-35deg, #923799, #0C909A)"; /* Standard syntax */
}

.recognitionOptions, .recognitionOptionsPrimary {
  /* padding: 40px; */
  overflow: hidden;
  border: 3px solid white;
  border-radius: 30px;
  /* margin-top:50px; */
  position: absolute;
}

.recognitionOptions input, .recognitionOptionsPrimary input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.recognitionOptions label, .recognitionOptionsPrimary label {
  float: left;
  display: inline-block;
  background-color: none;
  color: white;
  /* width: 200px; */
  /* font-size: 18px; */
  /* padding: 15px 20px; */
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  border: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.recognitionOptions label:hover, .recognitionOptionsPrimary label:hover {
  cursor: pointer;
}

.recognitionOptions input:checked + label {
  background-color: white;
  color: #943187;
  font-weight: bold;
  border-radius: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 9999;
}

.recognitionOptionsPrimary input:checked + label {
  background-color: white;
  color: #006699;
  font-weight: bold;
  border-radius: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 9999;
}

.recognitionOptions label:first-of-type {
  /* border-radius: 4px 0 0 4px; */
  /* border-radius:25px; */
  border-right: none;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 230px;
  /* position:absolute; */
}

.recognitionOptions label:last-of-type {
  /* border-radius: 0 4px 4px 0; */
  /* border-radius:25px; */
  border-left: none;
  /* position:absolute; */
  width: 230px;
  /* left:290px; */
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recognitionOptionsTampa {
  /* padding: 40px; */
  overflow: hidden;
  /* border: 3px solid white; */
  border-radius: 30px;
  /* margin-top:50px; */
  position: absolute;
}

.recognitionOptionsTampa input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.recognitionOptionsTampa label {
  margin-right: 1vh;
  background: white;
  padding: 1.18125vh 1.0416667vh;
  border-radius: 25px;
  float: left;
  display: inline-block;
  background-color: none;
  color: #694f28;
  /* width: 200px; */
  /* font-size: 18px; */
  /* padding: 15px 20px; */
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  border: none;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.recognitionOptionsTampa label:hover {
  cursor: pointer;
}

.recognitionOptionsTampa input:checked + label {
  padding: 0.78125vh 1.0416667vh;
  background-color: #f6c68e;
  color: #694f28;
  font-weight: bold;
  border-radius: 25px;
  border-color: white;
  border-width: 0.4vh;
  border-style: solid;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 9999;
}

.recognitionOptionsTampa label:first-of-type {
  /* border-radius: 4px 0 0 4px; */
  /* border-radius:25px; */
  padding: 1.18125vh 1.0416667vh;
  border-right: none;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 230px;
  /* position:absolute; */
}

.recognitionOptionsTampa label:last-of-type {
  /* border-radius: 0 4px 4px 0; */
  /* border-radius:25px; */
  border-left: none;
  /* position:absolute; */
  width: 230px;
  /* left:290px; */
  margin-right: 0px !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recognitionOptionsTampaFullPage h1 {
  color: #694f28;
}

.primaryButton-tampa {
  font-family: "OpenSans";
  font-size: 1.04vh;
  font-weight: bold;
  line-height: 1.25vh;
  text-transform: uppercase;
  /* background: linear-gradient(135deg, #008F8F, #95268E); */
  background: #102b77 !important;
  border: 0.2604166667vh solid #FFF !important;
  box-shadow: 0px 0.1vh 0.2vh 0px rgba(0, 0, 0, 0.5), 0px 0.05vh 0.05vh 0px rgba(82, 20, 78, 0.9), 0px 3px 8px 0px rgba(82, 20, 78, 0.5);
  border-radius: 2.6vh;
  color: white !important;
  cursor: pointer;
  display: flex;
  /* height: 4.167vh; */
  /* padding-bottom: 1.45vh; */
  padding-left: 1vh;
  padding-right: 1vh;
  /* padding-top: 1.45vh; */
  transition: box-shadow 0.125s ease-out;
  white-space: nowrap;
}

.primaryButton {
  color: #95268E;
  font-family: "OpenSans";
  font-size: 1.04vh;
  font-weight: bold;
  line-height: 1.25vh;
  text-transform: uppercase;
  background: linear-gradient(135deg, #008F8F, #95268E);
  border: none;
  box-shadow: 0px 0.1vh 0.2vh 0px rgba(0, 0, 0, 0.5), 0px 0.05vh 0.05vh 0px rgba(82, 20, 78, 0.9), 0px 3px 8px 0px rgba(82, 20, 78, 0.5);
  border-radius: 2.6vh;
  color: white !important;
  cursor: pointer;
  display: flex;
  /* height: 4.167vh; */
  /* padding-bottom: 1.45vh; */
  padding-left: 1vh;
  padding-right: 1vh;
  /* padding-top: 1.45vh; */
  transition: box-shadow 0.125s ease-out;
  white-space: nowrap;
}

.primaryButton-primary {
  color: #95268E;
  font-family: "OpenSans";
  font-size: 1.04vh;
  font-weight: bold;
  line-height: 1.25vh;
  text-transform: uppercase;
  background: #009900 !important;
  border: none;
  box-shadow: 0px 0.1vh 0.2vh 0px rgba(0, 0, 0, 0.5), 0px 0.05vh 0.05vh 0px rgba(121, 188, 67, 0.9), 0px 3px 8px 0px rgba(121, 188, 67, 0.5);
  border-radius: 2.6vh;
  color: white !important;
  cursor: pointer;
  display: flex;
  /* height: 4.167vh; */
  /* padding-bottom: 1.45vh; */
  padding-left: 1vh;
  padding-right: 1vh;
  /* padding-top: 1.45vh; */
  transition: box-shadow 0.125s ease-out;
  white-space: nowrap;
}

.primaryButton-keyboardSearch {
  color: white !important;
  border: none;
  /* height: 1.25vh!important; */
  margin-left: 0.4167vh;
  /* padding-top: 1.19vh; */
  width: 10.62vh;
}

.primaryButton-keyboardSearch .kspn {
  color: white !important;
}

.primaryButton-keyboardSearch .kspn-primary {
  color: white !important;
}

.gradient {
  -webkit-mask-image: linear-gradient(0deg, transparent 1%, rgb(0, 0, 0) 20%);
}

.gradient-upper {
  -webkit-mask-image: linear-gradient(180deg, transparent 1%, rgb(0, 0, 0) 20%);
}

.shadowed-image {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.clip-text {
  /* font-size: 6em;
  font-weight: bold; */
  line-height: 1;
  position: relative;
  display: inline-block;
  /* margin: .25em; */
  /* padding: .5em .75em; */
  text-align: center;
  /* Color fallback */
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.transparent-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

/* Background */
.clip-text:before {
  position: "absolute";
  width: 100%;
  height: 100%;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit;
  /* background-size:cover; */
}

/* Text Background (black zone) */
.clip-text:after {
  position: absolute;
  z-index: -1;
  top: 0.125em;
  right: 0.125em;
  bottom: 0.125em;
  left: 0.125em;
  background-color: #000;
}

/* Change the background position to display letter when the black zone isn't here */
.clip-text--no-textzone:before {
  background-position: -0.65em 0;
}

.clip-text--no-textzone:after {
  content: none;
}

/* Use Background-size cover for photo background and no-repeat background */
.clip-text--cover,
.clip-text--cover:before {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
}

.seattle-button:active {
  background-color: black;
}

.general-gradient-line {
  background: rgb(44, 44, 44);
  background: -moz-linear-gradient(top, rgb(44, 44, 44) 0%, rgb(0, 0, 0) 0%, rgba(28, 28, 28, 0.85) 9%, rgba(43, 43, 43, 0.72) 17%, rgba(17, 17, 17, 0.53) 29%, rgba(0, 0, 0, 0.33) 41%, rgba(18, 18, 18, 0.3) 43%, rgba(71, 71, 71, 0.3) 49%, rgba(102, 102, 102, 0.3) 68%, rgba(89, 89, 89, 0.3) 82%, rgba(0, 0, 0, 0.3) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgb(44, 44, 44)), color-stop(0%, rgb(0, 0, 0)), color-stop(9%, rgba(28, 28, 28, 0.85)), color-stop(17%, rgba(43, 43, 43, 0.72)), color-stop(29%, rgba(17, 17, 17, 0.53)), color-stop(41%, rgba(0, 0, 0, 0.33)), color-stop(43%, rgba(18, 18, 18, 0.3)), color-stop(49%, rgba(71, 71, 71, 0.3)), color-stop(68%, rgba(102, 102, 102, 0.3)), color-stop(82%, rgba(89, 89, 89, 0.3)), color-stop(100%, rgba(0, 0, 0, 0.3)));
  background: -webkit-linear-gradient(top, rgb(44, 44, 44) 0%, rgb(0, 0, 0) 0%, rgba(28, 28, 28, 0.85) 9%, rgba(43, 43, 43, 0.72) 17%, rgba(17, 17, 17, 0.53) 29%, rgba(0, 0, 0, 0.33) 41%, rgba(18, 18, 18, 0.3) 43%, rgba(71, 71, 71, 0.3) 49%, rgba(102, 102, 102, 0.3) 68%, rgba(89, 89, 89, 0.3) 82%, rgba(0, 0, 0, 0.3) 100%);
  background: -o-linear-gradient(top, rgb(44, 44, 44) 0%, rgb(0, 0, 0) 0%, rgba(28, 28, 28, 0.85) 9%, rgba(43, 43, 43, 0.72) 17%, rgba(17, 17, 17, 0.53) 29%, rgba(0, 0, 0, 0.33) 41%, rgba(18, 18, 18, 0.3) 43%, rgba(71, 71, 71, 0.3) 49%, rgba(102, 102, 102, 0.3) 68%, rgba(89, 89, 89, 0.3) 82%, rgba(0, 0, 0, 0.3) 100%);
  background: -ms-linear-gradient(top, rgb(44, 44, 44) 0%, rgb(0, 0, 0) 0%, rgba(28, 28, 28, 0.85) 9%, rgba(43, 43, 43, 0.72) 17%, rgba(17, 17, 17, 0.53) 29%, rgba(0, 0, 0, 0.33) 41%, rgba(18, 18, 18, 0.3) 43%, rgba(71, 71, 71, 0.3) 49%, rgba(102, 102, 102, 0.3) 68%, rgba(89, 89, 89, 0.3) 82%, rgba(0, 0, 0, 0.3) 100%);
  background: linear-gradient(to bottom, rgb(44, 44, 44) 0%, rgb(0, 0, 0) 0%, rgba(28, 28, 28, 0.85) 9%, rgba(43, 43, 43, 0.72) 17%, rgba(17, 17, 17, 0.53) 29%, rgba(0, 0, 0, 0.33) 41%, rgba(18, 18, 18, 0.3) 43%, rgba(71, 71, 71, 0.3) 49%, rgba(102, 102, 102, 0.3) 68%, rgba(89, 89, 89, 0.3) 82%, rgba(0, 0, 0, 0.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#2c2c2c", endColorstr="#000000", GradientType=0 );
}

.miami-bullet li {
  font-size: 1.5625vh;
}

.miami-subtitle p {
  margin-block-start: 0.651vh;
}

.miami-video video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.seattle-video::-webkit-media-controls {
  display: none;
}

.alpha-target {
  mask-mode: alpha;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 1.6%;
  -webkit-mask-position-x: center;
}

/* Background image from http://thepatternlibrary.com/ and http://lorempixel.com */
/* .clip-text_one {
    background-image: url(https://4kwallpapers.co/wp-content/uploads/2015/09/abstract-colorful-vectors-design-4k-wallpapers.png);
} */

.p-without-margin > p {
  margin: 0px !important;
}

html {
  -webkit-tap-highlight-color: transparent !important;
}

.totally-centered {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.preview-slide {
  max-width: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome and Opera */
}

.video-no-fullscreen video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.whiteBottomTransparentTop {
  background-image: -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 100%);
  background-image: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 100%);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.transparentTopWhiteBottom {
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 100%);
  background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 100%);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.quotation-mark:before {
  content: "“";
}

.coburg-donor-lists_donor-lists-items p {
  cursor: pointer;
}

