@import '../../node_modules/rc-color-picker-corbin/dist/rc-color-picker.css';
:export {
  titlebarColor: #2699FB;
}

.admin-main {
  margin: 12px !important;
  margin-top: 70px !important;
  padding: 16px !important;
}

body {
  background-color: white;
  margin: 0 !important;
}

.bread-crumbs {
  padding: 0px;
  overflow: hidden !important;
  position: fixed !important;
  top: 46px !important;
  left: 0px;
  width: 100%;
  border-top: 1px solid rgb(172, 172, 172);
  border-bottom: 1px solid rgb(172, 172, 172);
  background-color: #d9d9d9 !important;
  z-index: 1px;
}

.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexright {
  display: flex;
  justify-content: right;
  align-items: right;
}

.ant-menu {
  height: 45px;
}

.ant-tabs-nav {
  padding-left: 12px;
}

#center_tabs_container > .ant-tabs-top > .ant-tabs-nav {
  background-color: white;
  z-index: 5;
}

/* Get rid of underline on hover/active */
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom: none;
  color: inherit;
}

header {
  margin-bottom: 12px;
  background-color: #d9d9d9 !important;
}

.systemadmin-menu {
  padding-right: 0;
}

.form-field-wrapper {
  padding: 6px;
}

.file-input-wrapper input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input-wrapper input[type=file] + label {
  border: #d9d9d9 1px solid;
  border-radius: 3px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  padding-right: 15px;
  padding-left: 15px;
  display: inline-block;
  margin-bottom: 10px;
}

.file-input-wrapper input[type=file]:focus + label,
.file-input-wrapper input[type=file] + label:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.file-input-wrapper input[type=file]:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.file-input-wrapper input[type=file] + label {
  cursor: pointer; /* "hand" cursor */
}

.file-input-wrapper input[type=file] + label * {
  pointer-events: none;
}

.file-input-wrapper .file-name,
.file-input-wrapper .file-remove {
  margin-top: 30px;
  word-break: break-all;
}

.file-input-wrapper .file-preview {
  word-break: break-all;
  margin-top: 25px;
}

.file-input-wrapper .file-name-no-title {
  word-break: break-all;
}

.file-input-wrapper .file-preview img,
.file-input-wrapper .file-preview-no-title img {
  max-width: 50px;
  max-height: 40px;
}

.form-wrapper {
  padding: 12px;
}

.form-field-error {
  color: red;
  font-weight: bold;
}

.form-input-label {
  font-weight: bold;
  line-height: 1.9em;
  color: rgba(24, 41, 52, 0.85);
}

.form-submit-button {
  text-transform: uppercase;
}

.auth-container_bg {
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}
.auth-container_form {
  margin: 0 40px;
}
.auth-container_form-panel {
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 400px;
}
.auth-container_form-container {
  height: 100vh;
  background: linear-gradient(#5197F3, #1864B7);
}
.auth-container_form-input {
  margin: 0 15px;
}
.auth-container_form-input-container {
  margin: 20px auto 15px;
}
.auth-container_form-input-text {
  border-radius: 15px;
}
.auth-container_form-input-recaptcha {
  margin-bottom: 25px;
}
.auth-container_form-input-recaptcha > div {
  margin: 0 auto;
}
.auth-container_form-input-recaptcha > div > div {
  margin: 0 auto;
}
.auth-container_form-input-recaptcha .rc-anchor {
  border-radius: 30px;
}
.auth-container_logo {
  width: 100%;
}
.auth-container_logo-link {
  cursor: pointer;
}
.auth-container_h2 {
  color: white !important;
  font-size: 1.3em;
  font-weight: 600;
}
.auth-container_h3 {
  color: white !important;
  font-size: 1em;
}
.auth-container_button {
  color: white;
  font-weight: bold;
  background: #0E305C;
  border: none;
  border-radius: 15px;
}
.auth-container_link {
  color: white !important;
  text-decoration: white underline !important;
}
.auth-container_links {
  margin: 40px 0 0;
}

.auth-container-password-reset-request_form-panel {
  top: 30%;
}

.login-form {
  text-align: center;
}
.login-form-field {
  padding: 4px;
  margin: auto;
}
.login-form-submit {
  width: 50%;
}

.pull-right {
  float: right;
}

.rcorners1 {
  border-radius: 25px;
  padding: 8px;
  border-style: "dotted";
  height: 50;
  color: "white";
  border-color: "white";
  text-align: "center";
}

.s-left-panel-wrap {
  border-right: 1px solid #d1d1d1;
  transition: 0.3s ease-out;
}
.s-left-panel-wrap.slide-in {
  transform: translateX(0%);
}
.s-left-panel-wrap.slide-out {
  transform: translateX(-100%);
}

.s-panel-wrap {
  transition: 0.3s ease-out;
  background-color: white;
  border-left: 1px solid #d1d1d1;
}

.slide-in {
  transform: translateX(0%);
}

.slide-out {
  transform: translateX(100%);
}

.s-panel {
  color: black;
  overflow: auto;
}
.s-panel-bottom-border {
  border-bottom: 1px solid #f0f0f0;
}

.search-custom-collapse .search-custom-panel .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ReactVirtualized__Grid {
  outline: none;
}

.hide {
  display: none;
}

.ant-progress-circle .ant-progress-text {
  color: white;
}

.margin-for-open-button .ant-tabs-nav.ant-tabs-nav-animated {
  margin-left: 50px;
}

#center_tabs_container .ant-tabs-bar.ant-tabs-top-bar {
  display: flex;
  flex-direction: row;
  background: white;
  z-index: 5;
  margin-bottom: 0;
}
#center_tabs_container .ant-tabs-extra-content {
  max-width: 100%;
  background: white;
  z-index: 5;
  order: 2;
}
#center_tabs_container .ant-tabs-nav-container {
  background: white;
  z-index: 5;
  flex: 1;
  margin-bottom: -2px;
  border-bottom: 1px solid #e8e8e8;
}

.file-input-wrapper input[type=file] + label.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.file-input-wrapper input[type=file] + label.disabled:hover {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}

.center-tab {
  z-index: 10;
}

.react-crop-background {
  margin-left: 1px;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.ant-modal-close-x {
  color: "white";
}

.dynamic-file-input .dynamic-file-input_h2 {
  font-weight: normal;
  font-size: 0.9em;
  line-height: 2em;
  color: #A0A0A7;
}
.dynamic-file-input .dynamic-file-input_image-container {
  padding-top: 10px;
}
.dynamic-file-input .dynamic-file-input_add-button .preview-gallery_inner {
  height: 100% !important;
  width: 100% !important;
}
.dynamic-file-input .dynamic-file-input_add-button .preview-gallery_inner--disabled {
  height: 96% !important;
}
.dynamic-file-input .dynamic-file-input_add-button .preview-gallery_inner svg {
  width: 0.9em;
}
.dynamic-file-input_add-button .preview-gallery_inner {
  height: 90% !important;
  width: 90% !important;
}

.carousel-media-file-input_image-container {
  padding-top: 10px;
}
.carousel-media-file-input_card:first-child {
  margin-left: auto;
}
.carousel-media-file-input_card {
  opacity: 0.5;
}
.carousel-media-file-input_card-active {
  opacity: 1;
}
.carousel-media-file-input_card-active .preview-gallery_container-full .preview-gallery_outer {
  border: solid 3px #2999FF;
  padding-top: 94%;
}
.carousel-media-file-input_card-active .preview-gallery_container-full .preview-gallery_outer .preview-gallery_inner {
  top: 0px;
}
.carousel-media-file-input_card-active .preview-gallery__icons-video-center {
  margin: 22px 26px;
}

.preview-gallery_container {
  width: 100px;
  background-color: #FFFFFF;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080), linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080);
  background-size: 5px 5px;
  background-position: 0 0, 2.5px 2.5px;
}
.preview-gallery_container-sm {
  width: 10%;
}
.preview-gallery_container-full {
  width: 82px;
}
.preview-gallery_container-button {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}
.preview-gallery_container-button .preview-gallery_inner {
  height: 95%;
  width: 95%;
}
.preview-gallery_outer {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}
.preview-gallery_outer:hover .preview-gallery__icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-gallery_outer:hover .preview-gallery__icons-overlap {
  display: block;
}
.preview-gallery_inner-button {
  width: 100%;
  height: 100%;
}
.preview-gallery_inner-button svg {
  left: -3px;
}
.preview-gallery_inner-button span svg {
  left: -3.5px !important;
}
.preview-gallery_inner {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #CCC;
}
.preview-gallery__icons-container {
  display: none;
  background-color: black;
  width: 100%;
  height: 100%;
}
.preview-gallery__icons-overlap {
  display: none;
  position: absolute;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}
.preview-gallery__icons-video-container {
  display: block;
  background-color: black;
  width: 100%;
  height: 100%;
}
.preview-gallery__icons-video-overlap {
  display: block;
  position: absolute;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}
.preview-gallery__icons-video-center {
  margin: 24px 28px;
}
.preview-gallery__icons-video-center svg {
  cursor: inherit !important;
}
.preview-gallery_big-icon {
  font-size: 1.5em;
}
.preview-gallery_icon {
  position: absolute;
  font-size: 1.2em;
  color: white;
  z-index: 100;
}
.preview-gallery_icon-left {
  position: absolute;
  top: 40%;
  left: 15%;
}
.preview-gallery_icon-right {
  position: absolute;
  top: 40%;
  right: 15%;
}
.preview-gallery_outer-media {
  border: 1px solid grey;
}
.preview-gallery_image-container div {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.preview-gallery_image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}
.preview-gallery_image-container svg {
  padding: 30%;
  width: 100% !important;
  height: 100% !important;
}
.preview-gallery_spinner {
  padding: 12%;
}
.preview-gallery_spinner-disabled {
  padding: 12%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: gray;
  opacity: 0.5;
}
.preview-gallery_spinner-disabled svg {
  cursor: default !important;
}

.modal_content_manager .ant-modal-title {
  font-size: 18px;
}
.modal_content_manager .ant-modal-close-icon {
  font-size: 20px;
}

.content-manager {
  height: auto;
}
.content-manager_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.content-manager_container-left {
  flex: 3;
}
.content-manager_container-left .carousel-media-file-input_image-container {
  justify-content: flex-end;
}
.content-manager_container-right {
  padding: 0 20px;
  flex: 1;
}
.content-manager_container-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  left: 0;
}
.content-manager_section {
  display: flex;
  width: 100%;
}
.content-manager_section-top {
  flex: 4;
}
.content-manager_section-bottom {
  background: #F0F0F0;
  flex: 1;
  display: flex;
  height: 110px;
}
.content-manager_section-bottom .preview-gallery_container {
  padding-right: 10%;
  padding-bottom: 8%;
  background: none;
}
.content-manager_section-bottom-left {
  border-right: black 1px solid;
  margin: 17px;
  flex: 3;
  position: relative;
}
.content-manager_section-bottom-left .carousel-media-file-input_image-container {
  padding-top: 0;
  padding-right: 10px;
  overflow: auto;
  display: flex;
  flex-wrap: unset;
}
.content-manager_section-bottom-right {
  flex: 1;
  padding: 18px 0 10px 15px;
}
.content-manager_section-bottom-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.content-manager_section-bottom .carousel-media-file-input {
  width: 100%;
}
.content-manager_image {
  position: relative;
  display: flex;
  justify-content: center;
}
.content-manager_image-container .image-loader {
  display: flex;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  border: grey 1px solid;
}
.content-manager_image-container .image-loader img {
  object-fit: cover;
  height: 370px;
}
.content-manager_image-container .image-loader svg {
  height: 380px;
  width: 10em;
}
.content-manager_image-label {
  position: absolute;
  left: 46.5%;
  top: 5%;
}
.content-manager_image-label-container {
  position: relative;
  width: 50px;
  height: 25px;
  text-align: center;
}
.content-manager_image-label-overlap {
  background: #FFFFFF;
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 1px #D5D9DB;
}
.content-manager_image-label-text {
  top: 5px;
  left: 0px;
  position: absolute;
  width: inherit;
  font-weight: bolder;
  color: #808080;
  text-transform: uppercase;
  font-size: 11px;
}
.content-manager_image-button {
  color: #40B2F9;
  background: #E6EAE9;
  z-index: 1000;
}
.content-manager_image-button-prev {
  position: absolute;
  left: 3%;
  top: 50%;
}
.content-manager_image-button-next {
  position: absolute;
  right: 3%;
  top: 50%;
}
.content-manager_image-buttons {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
}
.content-manager_image-buttons button {
  width: 160px;
  font-size: 13px;
  font-weight: bold;
}
.content-manager_image-buttons-left button {
  margin-right: 20px;
}
.content-manager_image-buttons-right button {
  background: #F52621;
  color: white;
}
.content-manager_spinner {
  position: relative;
}
.content-manager_spinner svg {
  position: absolute;
  left: 42%;
  top: 110px;
}
.content-manager_form-header {
  padding: 0 0 20px;
}
.content-manager_form-header-h1 {
  font-size: 1.6em;
  font-weight: bold;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-manager_form-header-h2 {
  font-size: 0.8em;
  font-weight: bold;
  font-style: italic;
}
.content-manager_form-h1 {
  font-size: 1em;
  font-weight: bold;
  padding: 5px 0;
}
.content-manager_form-notes {
  font-weight: lighter;
  font-size: 0.8em;
}
.content-manager_form-field {
  padding: 5px 0 15px;
}
.content-manager_form-buttons {
  display: flex;
  justify-content: flex-end;
}
.content-manager_form-button {
  margin-left: 10px;
  text-transform: uppercase;
}
.content-manager_footer {
  display: flex;
  padding: 40px 0 0;
  justify-content: flex-end;
}
.content-manager_view-add-button .preview-gallery_inner {
  height: 90% !important;
  width: 90% !important;
}

.image-cropper_container-dark {
  background: darkblue;
}
.image-cropper_container-white {
  background-color: #FFFFFF;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080), linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080);
  background-size: 15px 15px;
  background-position: 0 0, 7.5px 7.5px;
}
.image-cropper_button {
  text-transform: uppercase;
}

.content-manager-widget {
  position: relative;
  display: flex;
  justify-content: center;
}
.content-manager-widget_images {
  position: relative;
}
.content-manager-widget_images:hover .content-manager-widget_buttons-main {
  display: block;
}
.content-manager-widget_images-container {
  display: flex;
}
.content-manager-widget_images-container .ant-carousel {
  width: 40px;
}
.content-manager-widget_images-container .preview-gallery_container {
  height: 100%;
  width: 100%;
}
.content-manager-widget_images-processing {
  width: 37px;
  border: solid 1px black;
  height: 37px;
  padding: 9px;
}
.content-manager-widget_carousel {
  display: flex;
}
.content-manager-widget_buttons {
  position: relative;
  width: 25px;
}
.content-manager-widget_buttons-main {
  display: none;
  position: absolute;
  top: 0%;
  left: 0%;
}
.content-manager-widget_buttons-add {
  position: absolute;
  top: -20px;
  left: 0%;
}
.content-manager-widget_button {
  color: #40B2F9;
  background: #E6EAE9;
  z-index: 1000;
  width: 15px;
  height: 13px;
  padding: 0;
}
.content-manager-widget_button svg {
  font-size: 0.9em;
  top: 0px;
  position: absolute;
}
.content-manager-widget_button-prev {
  position: absolute;
  left: 3%;
  top: 32%;
}
.content-manager-widget_button-prev svg {
  left: 2px;
}
.content-manager-widget_button-next {
  position: absolute;
  right: 3%;
  top: 32%;
}
.content-manager-widget_button-next svg {
  right: 1px;
}
.content-manager-widget_button-overlap {
  background: black;
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.content-manager-widget_button-main {
  position: absolute;
  top: 34%;
  left: 50%;
  background: transparent;
  color: white;
}

.content-uploader {
  height: 100%;
}
.content-uploader_dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.content-uploader_buttons {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.content-uploader_buttons-main {
  display: flex;
  flex-direction: column;
}
.content-uploader_buttons-main .ant-btn {
  margin: 4px 0;
}
.content-uploader_buttons-cancel {
  justify-content: center;
  display: flex;
  margin: 40px 0 0;
}
.content-uploader_dnd {
  margin: 40px 0;
}
.content-uploader_h1 {
  font-size: 1.5em;
  font-weight: bold;
}
.content-uploader_image {
  display: flex;
  justify-content: center;
}
.content-uploader_separator {
  margin: 10px 0;
  display: flex;
}
.content-uploader_separator-line {
  border-bottom: 1px grey solid;
  /* width: 100%; */
  /* background: green; */
  justify-content: center;
  width: 100px;
  height: 1px;
  margin: 10px 20px;
}

.flex-top-left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: center;
}

.flex-top-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.flex-top-right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: center;
}

.flex-bottom-left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: baseline;
  align-content: center;
}

.flex-bottom-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.flex-bottom-right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: center;
}

.flex-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-center-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.background-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.media-progress-status {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 300px;
  height: 230px;
  z-index: 20;
  background: white;
  border: solid 1px grey;
}
.media-progress-status-minimized {
  height: 4.5%;
}
.media-progress-status_header {
  display: flex;
  padding: 10px;
  background: #323232;
  color: white;
}
.media-progress-status_header-title {
  flex-grow: 2;
}
.media-progress-status_header-buttons {
  width: 60px;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
}
.media-progress-status_body {
  padding: 10px;
}
.media-progress-status_body-empty {
  justify-content: center;
  padding: 5%;
}
.media-progress-status_body-col {
  padding: 5px 0;
}
.media-progress-status_body-col-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
}
.media-progress-status_body-row {
  width: "100%";
}
.media-progress-status_icon-loading {
  color: #8a9ba8;
}
.media-progress-status_icon-error {
  color: red;
}
.media-progress-status_icon-complete {
  color: green;
}

.switch-company-menu {
  cursor: pointer;
  margin-left: 20px;
}

.unsaved-changes-modal_header {
  text-align: center;
  font-size: 18px;
}

.button-delete > button:hover {
  background: #f36460 !important;
}

.button-delete > button {
  background: red !important;
  color: white !important;
}

#folder_detail_form_wrapper form > div:first-child {
  margin-bottom: 60px;
}
#folder_detail_form_wrapper form > div:last-child {
  position: absolute;
  text-align: right;
  bottom: 5px;
  right: 40px;
}

.add_folder_form {
  height: 100%;
  position: relative;
}
.add_folder_form .form-wrapper {
  height: 100%;
}
.add_folder_form form {
  height: 100%;
}
.add_folder_form form > div:first-child {
  height: calc(100% - 150px);
  overflow: auto;
}
.add_folder_form form > div:last-child {
  width: 100% !important;
  border-top: #F0F0F0 solid 1px;
  padding: 10px 16px !important;
  position: absolute;
  bottom: 5px;
}
.add_folder_form form > div:last-child .form-field-wrapper {
  padding: 0px;
}

#center_tabs_container .add_folder_form .dynamic-form-name {
  font-size: 24px;
}
#center_tabs_container .add_folder_form form .image-select-input-header {
  font-size: 24px;
  text-align: left !important;
  font-weight: bold;
}

.ant-modal-content .add_folder_form form > div:first-child {
  height: auto;
}
.ant-modal-content .add_folder_form form > div:first-child:not(:has(.image-select-input-header)) {
  margin-bottom: 56px;
}
.ant-modal-content .add_folder_form form > div:last-child {
  width: calc(100% + 42px) !important;
  margin: -24px;
}

.list-view_button {
  text-transform: uppercase;
}
.list-view_header-hover {
  background: #2999FB;
  color: white;
  opacity: 0.5;
  padding: 15px 0px 15px 0px;
}
.list-view_header-hover .filter-action_icon-filtered {
  color: white;
}

.filter-action_container {
  position: relative;
}
.filter-action_buttons {
  position: absolute;
  right: 0;
  top: -12px;
  padding: 12px 5px;
}
.filter-action_icon {
  font-size: 11px;
  opacity: 0.6;
}
.filter-action_icon-filtered {
  color: #1890ff;
}
.filter-action_icon:hover {
  color: white;
}

.filter-action-modal_body {
  padding-bottom: 5px;
}
.filter-action-modal_buttons {
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
}

/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.no-pointer-events {
  pointer-events: none;
}

.resource-detail .ant-list-header {
  background: #FAFAFA;
  width: 100%;
}
.resource-detail_header-text {
  font-size: 18px;
  font-weight: bold;
}
.resource-detail_panel .ant-collapse-header {
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65) !important;
}
.resource-detail .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.resource-detail .ant-empty {
  margin: 0;
}
.resource-detail .ant-empty-image {
  display: none;
}
.resource-detail_actions {
  display: flex;
  margin: 6px 0;
  justify-content: flex-end;
  position: absolute;
  bottom: 5px;
  right: 20px;
}

.resource-content-library-detail {
  width: 100%;
}
.resource-content-library-detail_header-text {
  font-size: 18px;
  font-weight: bold;
}

.resource-content-library-associated {
  margin: 0;
  width: 100%;
}
.resource-content-library-associated_associated-side {
  display: flex;
}
.resource-content-library-associated_associated-side-left {
  margin-top: 2px;
  margin-right: 10px;
}
.resource-content-library-associated_associated-side-folder {
  font-size: 18px;
}
.resource-content-library-associated_associated-side-path {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4392156863);
}
.resource-content-library-associated_associated-side-icon {
  color: #2999FB;
}
.resource-content-library-associated_view-side-icon {
  font-size: 16px;
}

.resource-content-library-user-generated {
  width: 100%;
}
.resource-content-library-user-generated_header-text {
  font-size: 18px;
  font-weight: bold;
}
.resource-content-library-user-generated_name {
  width: 90%;
}
.resource-content-library-user-generated_notes {
  display: flex;
}
.resource-content-library-user-generated_notes-list {
  width: 90%;
}
.resource-content-library-user-generated_notes-extra {
  font-weight: 600;
}
.resource-content-library-user-generated_notes-separator {
  border-bottom: solid 1px rgba(128, 128, 128, 0.2588235294);
  margin: 15px 0 10px;
}
.resource-content-library-user-generated_notes-actions {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
}
.resource-content-library-user-generated_notes-actions-icon {
  color: #2699FB;
  padding: 2px;
  font-size: 18px !important;
  margin-top: 5px;
}
.resource-content-library-user-generated_notes-actions-edit {
  margin-left: 5px;
}
.resource-content-library-user-generated_notes-actions-delete {
  margin-left: 5px;
}
.resource-content-library-user-generated_notes-form {
  margin: 0 0 10px 0;
}
.resource-content-library-user-generated_notes-form-buttons {
  display: flex;
  justify-content: flex-end;
}
.resource-content-library-user-generated_tag-actions-icon {
  color: #2699FB;
  padding: 2px;
  font-size: 18px !important;
  margin-top: 5px;
}
.resource-content-library-user-generated_tag-itemtag {
  margin: 2px;
  padding: 2px 5px;
  font-size: 14px;
  text-transform: capitalize;
}
.resource-content-library-user-generated_listitem {
  position: relative;
  min-height: 40px;
}
.resource-content-library-user-generated_listitem-left {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.resource-content-library-user-generated_listitem-right {
  width: 80%;
}
.resource-content-library-user-generated_listitem-notes {
  min-height: 40px;
  padding-right: 15px !important;
}
.resource-content-library-user-generated_listitem-status {
  display: flex;
  align-items: self-start;
}
.resource-content-library-user-generated_listitem-header {
  height: 30px;
}
.resource-content-library-user-generated_listitem-title {
  width: 18%;
}
.resource-content-library-user-generated_listitem-content {
  width: 88%;
  margin: 40px 0px 0 80px;
}
.resource-content-library-user-generated_listitem-action {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 23px;
  width: 5%;
}
.resource-content-library-user-generated_listitem-name {
  display: flex;
  align-items: self-start;
}
.resource-content-library-user-generated_actions {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}
.resource-content-library-user-generated_available-form .form-field-wrapper {
  padding: 0;
}

.like-alink {
  cursor: pointer;
  color: #2699FB;
  text-decoration: none;
}

.like-alink:hover {
  text-decoration: underline;
  color: #2699FB;
}

.admin-display-table .ant-table .ant-table-tbody > tr:hover > td {
  background: #2699FB;
  color: white !important;
}
.admin-display-table .ant-table .ant-table-tbody > tr:hover > td a {
  color: white !important;
}

#center_tabs_container .ant-tabs-content {
  width: 100% !important;
  height: 100% !important;
}

#center_tabs_container .ant-tabs-content-holder {
  width: 100% !important;
  height: 100% !important;
}

.preview-transition {
  height: 100%;
  width: 100%;
}
.preview-transition img {
  width: 100%;
}

.preview-transition-natural {
  height: 100%;
  width: 100%;
}

.preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eugene-template-002_body-text br {
  content: none !important;
}
.eugene-template-002_subhead br {
  content: none !important;
}
.eugene-template-003_body-text br {
  content: none !important;
}
.eugene-template-003_subhead br {
  content: none !important;
}
.eugene-template-004_body-text br {
  content: none !important;
}
.eugene-template-004_subhead br {
  content: none !important;
}
.eugene-template-005_body-text br {
  content: none !important;
}
.eugene-template-005_subhead br {
  content: none !important;
}

.auth-password-input-popover .ant-popover-arrow {
  border-left-color: #0E305C !important;
  border-bottom-color: #0E305C !important;
}
.auth-password-input-popover .ant-popover-inner {
  background: transparent;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.auth-password-input-popover .ant-popover-inner-content {
  background: #0E305C;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.auth-password-input-popover_container {
  color: white;
}
.auth-password-input-popover_header {
  font-size: 16px;
  padding-bottom: 10px;
}
.auth-password-input-popover_body {
  font-size: 12px;
}
.auth-password-input-popover_rule {
  display: flex;
}
.auth-password-input_input {
  border-radius: 20px;
}

.form-input-color_container {
  position: relative;
  width: 100px;
  padding-right: 1%;
  padding-bottom: 1%;
}
.form-input-color_container:hover .form-input-color_outer {
  opacity: 0.3;
}
.form-input-color_container:hover .form-input-color_outer-overlay {
  opacity: 1;
}
.form-input-color_container-sm {
  width: 10%;
}
.form-input-color_container-full {
  width: 82px;
}
.form-input-color_container-button {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  background-color: transparent !important;
}
.form-input-color_outer {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}
.form-input-color_outer-overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 5px;
  text-align: center;
}
.form-input-color_inner-button {
  width: 100%;
  height: 100%;
}
.form-input-color_inner-button svg {
  left: -3px;
}
.form-input-color_inner-button span svg {
  left: -3.5px !important;
}
.form-input-color_inner {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}
.form-input-color_inner--disabled {
  background: #f5f5f5 !important;
}
.form-input-color_tooltip .ant-popover-inner-content {
  padding: 5px;
}
.form-input-color_tooltip-body {
  padding-bottom: 5px;
}
.form-input-color_tooltip-buttons {
  border-top: 1px solid #f0f0f0;
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
}
.configuration_placeholder-form .form-input-color_container {
  width: 150px;
  border-radius: 10px;
}

.image-carousel-2103 > .slick-dots > ul {
  padding-inline-start: 0;
}

.scrollable-fix-container-eugene-donor-list_fade-top {
  -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}
.scrollable-fix-container-eugene-donor-list_fade-bottom {
  -webkit-mask-image: -webkit-gradient(linear, left 80%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}

.scrollable-fix-container_fade-top {
  -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}
.scrollable-fix-container_fade-bottom {
  -webkit-mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}
.scrollable-fix-container_fade-both {
  -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0))), -webkit-gradient(linear, left 50%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  -webkit-mask-composite: source-in;
}
.scrollable-menu-with-one-giving-level_fade-top {
  -webkit-mask-image: -webkit-gradient(linear, left 20%, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}
.scrollable-menu-with-one-giving-level_fade-bottom {
  -webkit-mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}

.backup-buttons {
  display: flex;
  justify-content: space-evenly;
}

.webview-buttons {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 1500px) {
  .backup-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .backup-buttons button {
    margin-bottom: 10px;
  }
}
@media (max-width: 1765px) {
  .webview-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .webview-buttons button {
    margin-bottom: 10px;
  }
}
/* Using plain CSS */
@media (max-width: 1400px) {
  .preview-gallery-container {
    width: 25%;
    padding-right: 3%;
    padding-bottom: 3%;
  }
}
/* sm */
@media (max-width: 576px) {
  .auth-container_form-panel {
    width: 100%;
  }
}
.ant-tabs.ant-tabs-top {
  overflow: visible;
}

.ant-collapse-content.ant-collapse-content-active {
  overflow: visible;
}

.grid-bg, .preview-image {
  background-color: #FFFFFF;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080), linear-gradient(45deg, #808080 25%, transparent 25%, transparent 75%, #808080 75%, #808080);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
}

.zoom-fields-wrapper {
  opacity: 0.1;
  transition: opacity 600ms ease-in-out;
  -webkit-transition: opacity 600ms ease-in-out;
  -moz-transition: opacity 600ms ease-in-out;
}
.zoom-fields-wrapper:hover {
  opacity: 1;
}
.zoom-fields-wrapper .zoom-container {
  background: #505050;
  border-radius: 20px;
  padding: 5px;
}

.zoom-icon {
  color: white;
}

.zoom-input .ant-select-selector {
  border-radius: 10px !important;
}

.zoom-input {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

:export {
  titlebarColor: #2699FB;
}

.light-theme {
  background-color: white;
  color: black;
}
.light-theme body {
  background-color: white;
}
.light-theme .site-menu {
  background-color: #2699FB;
  color: white;
}
.light-theme .s-panel-wrap {
  background-color: white;
}
.light-theme .primary-tree-wrapper .search-highlight {
  color: #fb3f26;
}
.light-theme .s-panel, .light-theme h1, .light-theme h2, .light-theme h3, .light-theme h4, .light-theme h5, .light-theme h6 {
  color: black;
}
.light-theme .titlebar-color {
  color: #2699FB;
}
.light-theme .titlebar-text-color {
  color: white;
}
.light-theme .tree-background-color {
  color: white;
}
.light-theme .tree-text-color {
  color: #2699FB;
}

.light-theme-primary-tree {
  background-color: white;
  color: #2699FB;
}

.dark-theme {
  background-color: #31332F;
  color: #adbccb;
}
.dark-theme body {
  background-color: #31332F;
}
.dark-theme .site-menu {
  background-color: #2699FB;
  color: white;
}
.dark-theme .s-panel-wrap {
  background-color: #31332F;
}
.dark-theme .primary-tree-wrapper .ant-tabs-nav {
  color: white;
}
.dark-theme .primary-tree-wrapper .search-highlight {
  color: purple;
}
.dark-theme .s-panel, .dark-theme h1, .dark-theme h2, .dark-theme h3, .dark-theme h4, .dark-theme h5, .dark-theme h6, .dark-theme .ant-checkbox-wrapper, .dark-theme .ant-table {
  color: #adbccb;
}
.dark-theme .ant-collapse .ant-collapse-item .ant-collapse-header {
  background-color: #31332F;
  color: #adbccb;
}
.dark-theme .ant-input, .dark-theme .ant-collapse, .dark-theme .ant-collapse-content {
  background-color: #31332F;
  color: #adbccb;
}
.dark-theme .ant-collapse-header {
  color: #adbccb;
  background-color: #2f3136;
}
.dark-theme .ant-collapse-header .collapse-item {
  color: #adbccb;
}
.dark-theme .titlebar-color {
  color: #2699FB;
}
.dark-theme .titlebar-text-color {
  color: white;
}
.dark-theme .tree-background-color {
  color: white;
}
.dark-theme .tree-text-color {
  color: #2699FB;
}

.dark-theme-primary-tree {
  background-color: #31332F;
  color: white;
}

.site-menu {
  display: flex;
  justify-content: space-between;
  overflow: hidden !important;
  position: fixed !important;
  top: 0px !important;
  width: 100%;
  background-color: #d9d9d9;
}
.site-menu-logo {
  /* Right margin "auto" makes this element align right */
  padding: 3px 4px;
}
.site-menu-link {
  background: url("../../public/images/logo.png") center center no-repeat;
  background-size: cover;
  display: inline-block;
  height: 37px;
  width: 75px;
}
.site-menu-link span {
  display: none;
}
.site-menu-search {
  text-align: center;
  width: 100%;
}

.anticon-spin.ant-input-search-icon:before {
  border-left: none !important;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.color-primary.light-theme {
  color: #2699FB;
}

.color-primary.dark-theme {
  color: white;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

@media (min-width: 768px) {
  .only-mobile {
    display: none !important;
  }
  .site-menu-logo {
    /* Right margin "auto" makes this element align right */
    margin-right: auto;
  }
}
@media (max-width: 768px) {
  .only-pc {
    display: none !important;
  }
  .site-menu-logo {
    flex: 0;
  }
  .site-menu-item.mobile-actions {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .site-menu-item.mobile-actions span {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mr-20-per-sm {
    margin-right: 20%;
  }
  .primary-tree-wrapper {
    width: 100%;
  }
}

