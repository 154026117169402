.text-editor-wysiwyg .ql-editor p > br {
  content: "";
  font-size: 10em!important;
  display: block;
}

.text-editor-wysiwyg .ql-editor p > br::after {
  content: " ";
}

.text-editor-wysiwyg .ql-editor {
  font-size: 1.08px !important;
}

.text-editor-wysiwyg-dark .ql-editor p > br {
  font-size: 10em!important;
}

.text-editor-wysiwyg-dark .ql-editor {
  font-size: 1.08px !important;
  background-color: #1e1d1d;
}

.text-editor-wysiwyg-dark-px .ql-editor {
  background-color: #1e1d1d;
}

div:not(.text-editor-wysiwyg-dark-px) > div > .quill > .ql-container > .ql-editor {
  background-color: #e3e3e3;
}
.ql-editor-preview .ql-editor {
  background-color: transparent !important;
  overflow-y: hidden;
}

.text-editor-wysiwyg-panel .ql-editor {
  max-height: 50vh;
}

.ql-editor ol li::before {
  font-size: 12px;
  // margin-left: 0px!important;
}

.ql-editor ul li::before {
  font-size: 12px;
  // margin-left: 0px!important;
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 20em;
}

.ql-snow .ql-picker.ql-font, .ql-snow .ql-picker.ql-fontfamily {
  width: 100px;
}

.ql-fontfamily .ql-picker-label:before {
  content: attr(data-label);
}

.ql-fontfamily .ql-picker-item:before {
  content: attr(data-label);
}

.ql-picker-options {
  max-height: 250px;
  overflow-y: scroll;
}

.ql-size-1em {
  font-size: 1em;
}
.ql-size-2em {
  font-size: 2em;
}
.ql-size-3em {
  font-size: 3em;
}
.ql-size-4em {
  font-size: 4em;
}
.ql-size-5em {
  font-size: 5em;
}
.ql-size-6em {
  font-size: 6em;
}
.ql-size-7em {
  font-size: 7em;
}
.ql-size-8em {
  font-size: 8em;
}
.ql-size-9em {
  font-size: 9em;
}
.ql-size-10em {
  font-size: 10em;
}
.ql-size-11em {
  font-size: 11em;
}
.ql-size-12em {
  font-size: 12em;
}
.ql-size-13em {
  font-size: 13em;
}
.ql-size-14em {
  font-size: 14em;
}
.ql-size-15em {
  font-size: 15em;
}
.ql-size-16em {
  font-size: 16em;
}
.ql-size-17em {
  font-size: 17em;
}
.ql-size-18em {
  font-size: 18em;
}
.ql-size-19em {
  font-size: 19em;
}
.ql-size-20em {
  font-size: 20em;
}
.ql-size-21em {
  font-size: 21em;
}
.ql-size-22em {
  font-size: 22em;
}
.ql-size-23em {
  font-size: 23em;
}
.ql-size-24em {
  font-size: 24em;
}
.ql-size-25em {
  font-size: 25em;
}
.ql-size-26em {
  font-size: 26em;
}
.ql-size-27em {
  font-size: 27em;
}
.ql-size-28em {
  font-size: 28em;
}
.ql-size-29em {
  font-size: 29em;
}
.ql-size-30em {
  font-size: 30em;
}
.ql-size-31em {
  font-size: 31em;
}
.ql-size-32em {
  font-size: 32em;
}
.ql-size-33em {
  font-size: 33em;
}
.ql-size-34em {
  font-size: 34em;
}
.ql-size-35em {
  font-size: 35em;
}
.ql-size-36em {
  font-size: 36em;
}
.ql-size-37em {
  font-size: 37em;
}
.ql-size-38em {
  font-size: 38em;
}
.ql-size-39em {
  font-size: 39em;
}
.ql-size-40em {
  font-size: 40em;
}
.ql-size-41em {
  font-size: 41em;
}
.ql-size-42em {
  font-size: 42em;
}
.ql-size-43em {
  font-size: 43em;
}
.ql-size-44em {
  font-size: 44em;
}
.ql-size-45em {
  font-size: 45em;
}
.ql-size-46em {
  font-size: 46em;
}
.ql-size-47em {
  font-size: 47em;
}
.ql-size-48em {
  font-size: 48em;
}
.ql-size-49em {
  font-size: 49em;
}
.ql-size-50em {
  font-size: 50em;
}
.ql-size-51em {
  font-size: 51em;
}
.ql-size-52em {
  font-size: 52em;
}
.ql-size-53em {
  font-size: 53em;
}
.ql-size-54em {
  font-size: 54em;
}
.ql-size-55em {
  font-size: 55em;
}
.ql-size-56em {
  font-size: 56em;
}
.ql-size-57em {
  font-size: 57em;
}
.ql-size-58em {
  font-size: 58em;
}
.ql-size-59em {
  font-size: 59em;
}
.ql-size-60em {
  font-size: 60em;
}
.ql-size-61em {
  font-size: 61em;
}
.ql-size-62em {
  font-size: 62em;
}
.ql-size-63em {
  font-size: 63em;
}
.ql-size-64em {
  font-size: 64em;
}
.ql-size-65em {
  font-size: 65em;
}
.ql-size-66em {
  font-size: 66em;
}
.ql-size-67em {
  font-size: 67em;
}
.ql-size-68em {
  font-size: 68em;
}
.ql-size-69em {
  font-size: 69em;
}
.ql-size-70em {
  font-size: 70em;
}
.ql-size-71em {
  font-size: 71em;
}
.ql-size-72em {
  font-size: 72em;
}
.ql-size-73em {
  font-size: 73em;
}
.ql-size-74em {
  font-size: 74em;
}
.ql-size-75em {
  font-size: 75em;
}
.ql-size-76em {
  font-size: 76em;
}
.ql-size-77em {
  font-size: 77em;
}
.ql-size-78em {
  font-size: 78em;
}
.ql-size-79em {
  font-size: 79em;
}
.ql-size-80em {
  font-size: 80em;
}
.ql-size-81em {
  font-size: 81em;
}
.ql-size-82em {
  font-size: 82em;
}
.ql-size-83em {
  font-size: 83em;
}
.ql-size-84em {
  font-size: 84em;
}
.ql-size-85em {
  font-size: 85em;
}
.ql-size-86em {
  font-size: 86em;
}
.ql-size-87em {
  font-size: 87em;
}
.ql-size-88em {
  font-size: 88em;
}
.ql-size-89em {
  font-size: 89em;
}
.ql-size-90em {
  font-size: 90em;
}
.ql-size-91em {
  font-size: 91em;
}
.ql-size-92em {
  font-size: 92em;
}
.ql-size-93em {
  font-size: 93em;
}
.ql-size-94em {
  font-size: 94em;
}
.ql-size-95em {
  font-size: 95em;
}
.ql-size-96em {
  font-size: 96em;
}
.ql-size-97em {
  font-size: 97em;
}
.ql-size-98em {
  font-size: 98em;
}
.ql-size-99em {
  font-size: 99em;
}
.ql-size-100em {
  font-size: 100em;
}

.ql-size-8px {
  font-size: 0.692vw;
}

.ql-size-10px {
  font-size: 0.865vw;
}

.ql-size-12px {
  font-size: 1.041vw;
}

.ql-size-16px {
  font-size: 1.384vw;
}

.ql-size-18px {
  font-size: 1.557vw;
}

.ql-size-20px {
  font-size: 1.73vw;
}

.ql-size-22px {
  font-size: 1.903vw;
}

.ql-size-24px {
  font-size: 2.08vw;
}

.ql-size-26px {
  font-size: 2.249vw;
}

.ql-size-28px {
  font-size: 2.422vw;
}

.ql-size-30px {
  font-size: 2.595vw;
}

.ql-size-32px {
  font-size: 2.768vw;
}

.ql-size-34px {
  font-size: 2.941vw;
}

.ql-size-36px {
  font-size: 3.114vw;
}

.ql-size-38px {
  font-size: 3.287vw;
}

.ql-size-48px {
  font-size: 4.152vw;
}

.ql-size-60px {
  font-size: 5.19vw;
}
.ql-size-70px {
  font-size: 6.055vw;
}
.ql-size-125px {
  font-size: 10.8125vw;
}

.ql-font-serif {
  font-family: serif;
}

.ql-font-sansserif {
  font-family: sans-serif;
}

.ql-editor blockquote {
  /* background: #f9f9f9; */
  border-left: 1vw solid #ccc;
  margin: 1.5em 10px;
  padding: 1vw !important;
  quotes: "\201C""\201D""\2018""\2019";
}

.ql-editor-preview .ql-editor br {
  content: ""; display: block;
}

.ql-editor p:has(+ br) {
  font-size: 20em;
}

.ql-editor-preview .ql-editor br::after {
  content: " ";
  font-size: 10em;
}

.horizontal-screen-any {
  .ql-size-8px {
    font-size: 0.74074vh;
  }

  .ql-size-10px {
    font-size: 0.9255vh;
  }

  .ql-size-12px {
    font-size: 1.1106vh;
  }

  .ql-size-16px {
    font-size: 1.4808vh;
  }

  .ql-size-18px {
    font-size: 1.6666vh;
  }

  .ql-size-20px {
    font-size: 1.8511vh;
  }

  .ql-size-22px {
    font-size: 2.0362vh;
  }

  .ql-size-24px {
    font-size: 2.2213vh;
  }

  .ql-size-26px {
    font-size: 2.407vh;
  }

  .ql-size-28px {
    font-size: 2.592vh;
  }

  .ql-size-30px {
    font-size: 2.7766vh;
  }

  .ql-size-32px {
    font-size: 2.962vh;
  }

  .ql-size-34px {
    font-size: 3.148vh;
  }

  .ql-size-36px {
    font-size: 3.332vh;
  }

  .ql-size-38px {
    font-size: 3.519vh;
  }

  .ql-size-48px {
    font-size: 4.4426vh;
  }

  .ql-size-54px {
    font-size: 4.998vh;
  }

  .ql-size-60px {
    font-size: 5.5533vh;
  }
  .ql-size-70px {
    font-size: 6.4788vh;
  }
  .ql-size-125px {
    font-size: 11.5694vh;
  }
}

.horizontal-screen-x2 {
  .ql-size-8px {
    font-size: 0.692vw;
  }

  .ql-size-10px {
    font-size: 0.865vw;
  }

  .ql-size-12px {
    font-size: 1.041vw;
  }

  .ql-size-16px {
    font-size: 1.384vw;
  }

  .ql-size-18px {
    font-size: 1.557vw;
  }

  .ql-size-20px {
    font-size: 1.73vw;
  }

  .ql-size-22px {
    font-size: 0.9515vw;
  }

  .ql-size-24px {
    font-size: 2.08vw;
  }

  .ql-size-30px {
    font-size: 2.595vw;
  }

  .ql-size-36px {
    font-size: 3.114vw;
  }

  .ql-size-38px {
    font-size: 3.287vw;
  }

  .ql-size-48px {
    font-size: 4.152vw;
  }

  .ql-size-60px {
    font-size: 5.19vw;
  }
  .ql-size-70px {
    font-size: 6.055vw;
  }
  .ql-size-125px {
    font-size: 10.8125vw;
  }
}

.vertical-screen-x1 {
  .ql-size-8px {
    font-size: 0.416vh;
  }

  .ql-size-10px {
    font-size: 0.52vh;
  }

  .ql-size-12px {
    font-size: 0.624vh;
  }

  .ql-size-16px {
    font-size: 0.832vh;
  }

  .ql-size-18px {
    font-size: 0.936vh;
  }

  .ql-size-20px {
    font-size: 1.04vh;
  }

  .ql-size-22px {
    font-size: 1.144vh;
  }

  .ql-size-24px {
    font-size: 1.248vh;
  }

  .ql-size-30px {
    font-size: 1.56vh;
  }

  .ql-size-36px {
    font-size: 1.872vh;
  }

  .ql-size-38px {
    font-size: 1.973vh;
  }

  .ql-size-48px {
    font-size: 2.496vh;
  }

  .ql-size-54px {
    font-size: 2.808vh;
  }

  .ql-size-60px {
    font-size: 3.12vh;
  }
  .ql-size-70px {
    font-size: 3.64vh;
  }
  .ql-size-125px {
    font-size: 6.5vh;
  }
}


//ql-toolbar.ql-snow.ql-picker.ql-expanded.
