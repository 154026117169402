@font-face {
  font-family: 'abc-social-regular';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/abc-social/ABCSocial-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'abc-social-medium';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/abc-social/ABCSocial-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'abc-social-book';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/abc-social/ABCSocial-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'abc-social-bold';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/abc-social/ABCSocial-Bold.woff2') format('woff2');
}

.ql-font-abc-social-regular,
.ql-font span[data-value="abc-social-regular"]::before {
  font-family: "abc-social-regular";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

.ql-font-abc-social-medium,
.ql-font span[data-value="abc-social-medium"]::before {
  font-family: "abc-social-medium";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

.ql-font-abc-social-book,
.ql-font span[data-value="abc-social-book"]::before {
  font-family: "abc-social-book";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}

.ql-font-abc-social-bold,
.ql-font span[data-value="abc-social-bold"]::before {
  font-family: "abc-social-bold";
  font-variant: normal;
  -webkit-font-feature-settings: normal;
}