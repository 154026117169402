@font-face {
    font-family: 'open-sans-light';
    font-weight: 300;
    font-style: normal;
    src: local("Open Sans"), local("Open-Sans-Light"),
        url("../../fonts/open-sans/OpenSans-Light.woff2") format("woff2"),
        url("../../fonts/open-sans/OpenSans-Light.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'open-sans-regular';
    font-weight: 400;
    font-style: normal;
    src: local("Open Sans"), local("Open-Sans-Regular"),
        url("../../fonts/open-sans/OpenSans-Regular.woff2") format("woff2"),
        url("../../fonts/open-sans/OpenSans-Regular.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'open-sans-medium';
    font-weight: 400;
    font-style: normal;
    src: local("Open Sans"), local("Open-Sans-Medium"),
        url("../../fonts/open-sans/OpenSans-Medium.woff2") format("woff2"),
        url("../../fonts/open-sans/OpenSans-Medium.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'open-sans-semi-bold';
    font-weight: 600;
    font-style: normal;
    src: local("Open Sans"), local("Open-Sans-Semibold"),
        url("../../fonts/open-sans/OpenSans-SemiBold.woff2") format("woff2"),
        url("../../fonts/open-sans/OpenSans-SemiBold.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'open-sans-bold';
    font-weight: 600;
    font-style: normal;
    src: local("Open Sans"), local("Open-Sans-Bold"),
        url("../../fonts/open-sans/OpenSans-Bold.woff2") format("woff2"),
        url("../../fonts/open-sans/OpenSans-Bold.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ql-font-open-sans-regular,
.ql-font span[data-value="open-sans-regular"]::before {
    font-family: "open-sans-regular", sans-serif;
}

.ql-font-open-sans-light,
.ql-font span[data-value="open-sans-light"]::before {
    font-family: "open-sans-light", sans-serif;
}

.ql-font-open-sans-medium,
.ql-font span[data-value="open-sans-medium"]::before {
    font-family: "open-sans-medium", sans-serif;
}

.ql-font-open-sans-semi-bold,
.ql-font span[data-value="open-sans-semi-bold"]::before {
    font-family: "open-sans-semi-bold", sans-serif;
}

.ql-font-open-sans-bold,
.ql-font span[data-value="open-sans-bold"]::before {
    font-family: "open-sans-bold", sans-serif;
}