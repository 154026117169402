@font-face {
  font-family: 'gilroy-medium';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/gilroy/gilroy-medium.ttf') format('truetype');
}

.ql-font-gilroy-medium,
.ql-font span[data-value="gilroy-medium"]::before {
  font-family: "gilroy-medium"
}
