@use "sass:math";

// Icon Sizes
// -------------------------

// makes the font 33% larger relative to the icon container
.#{$fa-css-prefix}-lg {
  font-size: math.div(4em, 3);
  line-height: math.div(3em, 4);
  vertical-align: -15%;
}

.#{$fa-css-prefix}-xs {
  font-size: .75em;
}

.#{$fa-css-prefix}-sm {
  font-size: .875em;
}

@for $i from 1 through 10 {
  .#{$fa-css-prefix}-#{$i}x {
    font-size: $i * 1em;
  }
}
