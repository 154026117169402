@font-face {
  font-family: 'pantograph-extended-semibold';
  font-style: normal;
  src: url("../../fonts/pantograph-extended/pantograph-extended-semibold.otf") format('opentype'),
}


.ql-font-pantograph-extended-semibold,
.ql-font span[data-value="pantograph-extended-semibold"]::before {
  font-family: "pantograph-extended-semibold"
}

