$main-background: white;

// light theme
$theme-light-titlebar-color:#2699FB; // #34be5b;
$theme-light-titlebar-text-color: white;
$theme-light-primary-button: #2699FB; //#34be5b;
$theme-light-primary-button-hover: #5cc0ff;
$theme-light-left-background: white;

$theme-light-tree-background: white;
$theme-light-tree-text-color: #2699FB; //#34be5b;
$theme-light-tree-highlight: #fb3f26;

$theme-light-background: white;
$theme-light-background-deep: white;
$theme-light-text-main-color: black;

// dark theme
$theme-dark-titlebar-color: #2699FB;
$theme-dark-titlebar-text-color: white;
$theme-dark-primary-button: #2699FB;
$theme-dark-primary-button-hover: #5cc0ff;
$theme-dark-left-background: #31332F;

$theme-dark-tree-background: #31332F;
$theme-dark-tree-text-color: white;
$theme-dark-tree-highlight: purple;

$theme-dark-background: #31332F;
$theme-dark-background-deep: #2f3136;
$theme-dark-text-main-color: #adbccb;

:export {
  titlebarColor: $theme-light-titlebar-color
}