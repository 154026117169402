@font-face {
    font-family: 'cachet-pro-bold';
    font-style: normal;
    src: url("../../fonts/cachet-pro/Cachet Std Bold.otf") format('opentype'),
}

@font-face {
  font-family: 'cachet-pro-regular';
  font-style: normal;
  src: url("../../fonts/cachet-pro/Cachet Std Book.otf") format('opentype'),
}

@font-face {
  font-family: 'cachet-pro-medium';
  font-style: normal;
  src: url("../../fonts/cachet-pro/Cachet Std Medium.otf") format('opentype'),
}

.ql-font-cachet-pro-bold,
.ql-font span[data-value="cachet-pro-bold"]::before {
  font-family: "cachet-pro-bold"
}

.ql-font-cachet-pro-regular,
.ql-font span[data-value="cachet-pro-regular"]::before {
  font-family: "cachet-pro-regular"
}

.ql-font-cachet-pro-medium,
.ql-font span[data-value="cachet-pro-medium"]::before {
  font-family: "cachet-pro-medium"
}
