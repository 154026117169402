@import '_variables.scss';

// light theme

.light-theme{
  background-color: $theme-light-background;
  color: $theme-light-text-main-color;
  body {
    background-color: $theme-light-background;
  }
  // .ant-btn-primary {
  //   background-color: $theme-light-primary-button;
  // }
  // .ant-btn-primary:hover {
  //   background-color: $theme-light-primary-button-hover;
  // }
  .site-menu {
    background-color: $theme-light-titlebar-color;
    color: $theme-light-titlebar-text-color;
  }
  .s-panel-wrap{
    background-color: $theme-light-background;
  }
  .primary-tree-wrapper{
    // .ant-tabs-nav {
    //   color: $theme-light-tree-text-color
    // }
    .search-highlight{
      color: $theme-light-tree-highlight;
    }
  }
  .s-panel,  h1, h2, h3, h4, h5, h6 {
    color: $theme-light-text-main-color
  }
  // .s-panel,  h1, h2, h3, h4, h5, h6, .ant-checkbox-wrapper, .ant-table {
  //   color: $theme-light-text-main-color
  // }
  // .ant-collapse {
  //   .ant-collapse-item {
  //     .ant-collapse-header{
  //       background-color: $theme-light-background;
  //       color: $theme-light-text-main-color;
  //     }
  //   }
  // }
  // .ant-input, .ant-collapse, .ant-collapse-content {
  //   background-color: $theme-light-background;
  //   color: $theme-light-text-main-color;
  // }
  // .ant-collapse-header{
  //   color: $theme-light-text-main-color;
  //   background-color: $theme-light-background-deep;
  //   .collapse-item {
  //     color: $theme-light-text-main-color;
  //   }
  // }
  .titlebar-color{
    color: $theme-light-titlebar-color;
  }
  .titlebar-text-color{
    color: $theme-light-titlebar-text-color;
  }
  .tree-background-color{
    color: $theme-light-tree-background
  }
  .tree-text-color{
    color: $theme-light-tree-text-color
  }
}
.light-theme-primary-tree{
  background-color: $theme-light-tree-background;
  color: $theme-light-tree-text-color;
}


// dark theme
.dark-theme{
  background-color: $theme-dark-background;
  color: $theme-dark-text-main-color;
  body {
    background-color: $theme-dark-background;
  }
  .site-menu {
    background-color: $theme-dark-titlebar-color;
    color: $theme-dark-titlebar-text-color;
  }
  .s-panel-wrap{
    background-color: $theme-dark-background;
  }
  .primary-tree-wrapper{
    .ant-tabs-nav {
      color: $theme-dark-tree-text-color
    }
    .search-highlight{
      color: $theme-dark-tree-highlight;
    }
  }
  .s-panel,  h1, h2, h3, h4, h5, h6, .ant-checkbox-wrapper, .ant-table {
    color: $theme-dark-text-main-color
  }
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header{
        background-color: $theme-dark-background;
        color: $theme-dark-text-main-color;
      }
    }
  }
  .ant-input, .ant-collapse, .ant-collapse-content {
    background-color: $theme-dark-background;
    color: $theme-dark-text-main-color;
  }
  .ant-collapse-header{
    color: $theme-dark-text-main-color;
    background-color: $theme-dark-background-deep;
    .collapse-item {
      color: $theme-dark-text-main-color;
    }
  }
  .titlebar-color{
    color: $theme-light-titlebar-color;
  }
  .titlebar-text-color{
    color: $theme-light-titlebar-text-color;
  }
  .tree-background-color{
    color: $theme-light-tree-background
  }
  .tree-text-color{
    color: $theme-light-tree-text-color
  }
}
.dark-theme-primary-tree{
  background-color: $theme-dark-tree-background;
  color: $theme-dark-tree-text-color;
}
.site-menu {
  display: flex;
  justify-content: space-between;
  overflow: hidden !important;
  position: fixed !important;
  top: 0px !important;
  width: 100%;
  background-color: #d9d9d9;
  &-logo {
    /* Right margin "auto" makes this element align right */
    padding: 3px 4px;
  }
  &-link {
    background: url('../../public/images/logo.png') center center no-repeat;
    background-size: cover;
    display: inline-block;
    height: 37px;
    width: 75px;
    span {
      display: none;
    }
  }
  &-search {
    text-align: center;
    width: 100%
  }
}

.anticon-spin.ant-input-search-icon:before {
    border-left: none !important;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.color-primary.light-theme {
  color: $theme-light-tree-text-color;
}

.color-primary.dark-theme {
  color: $theme-dark-tree-text-color;
}

@for $i from 1 through 12 {
  .mt-#{5*$i} {
    margin-top: #{5*$i}px;
  }
}

// For PC screens
@media(min-width: 768px) {
  .only-mobile {
    display: none !important;
  }

  .site-menu-logo {
    /* Right margin "auto" makes this element align right */
    margin-right: auto;
  }
}

// For mobile screens
@media(max-width: 768px) {
  .only-pc {
    display: none !important;
  }

  .site-menu-logo {
    flex: 0;
  }

  .site-menu-item.mobile-actions {
    flex: 1;
    display: flex;
    justify-content: center;
    span {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .mr-20-per-sm {
    margin-right: 20%;
  }

  .primary-tree-wrapper {
    width: 100%;
  }
}
