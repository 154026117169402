@font-face {
    font-family: 'whitney-bold';
    src: url('../../fonts/whitney/Whitney-Bold-Pro.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'whitney-book';
    src: url('../../fonts/whitney/Whitney-Book-Pro.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'whitney-light';
    src: url('../../fonts/whitney/Whitney-Light-Pro.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'whitney-semibold';
    src: url('../../fonts/whitney/Whitney-Semi-Bold-Pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ql-font-whitney-bold,
.ql-font span[data-value="whitney-bold"]::before {
    font-family: "whitney-bold"
}

.ql-font-whitney-light,
.ql-font span[data-value="whitney-light"]::before {
    font-family: "whitney-light"
}

.ql-font-whitney-book,
.ql-font span[data-value="whitney-book"]::before {
    font-family: "whitney-book"
}

.ql-font-whitney-semibold,
.ql-font span[data-value="whitney-semibold"]::before {
    font-family: "whitney-semibold"
}
