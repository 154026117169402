@import './fonts.scss';
@import './font-awesome/fontawesome-pro-solid.scss';
@import './font-awesome/fontawesome-pro-core.scss';
@import './quill-customizations';
@import './denver-stories';
@import './denver-donor-list';
@import './general-text';
@import './transition-group';
// @import "../../node_modules/video-react/dist/video-react.css"; // import css

$cursor: pointer;

.float-right{
  float: right;
}
.cursor-main{
  cursor: $cursor; 
}
.phand{
  cursor: $cursor;//pointer;//
}
.outerC {
  display: table;
  width: 100%;
}
.middleC {
  display: table-cell;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
.innerC {
  width: 80vw;
  margin: auto;
}

.nodrag {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}
.backdropOn {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: .4; //.4
  background-color: black;
  transition: opacity 300ms linear;
  pointer-events: auto;
  width: 100vw;
  height: 100vh;
}
.backdropOff {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90; 
  background-color: black;
  opacity: 0;
  transition: opacity 300ms linear;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
}

#slideout_background {
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  //opacity: .8;
  color: #333;
  position: fixed;
  top: 0px;
  left: -30vw;
  width: 30vw;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

#slideout {
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  //opacity: .8;
  color: #333;
  position: fixed;
  top: 0px;
  left: -30vw;
  width: 30vw;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
#slideout {
  display: block;
  height: 100vh;
}

#slideout.on {
  left: 0;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aligner-item--top {
  align-self: flex-start;
}
.aligner-item--bottom {
  align-self: flex-end;
}

.valigner {
  display: flex;
  align-items: center;
}
.valigner-item--top {
  align-self: flex-start;
}
.valigner-item--bottom {
  align-self: flex-end;
}

.fadediv.fadeout {
   opacity: 0;
}
.fadediv {
   opacity: 1;
   transition: opacity .25s ease-in-out;
   -moz-transition: opacity .25s ease-in-out;
   -webkit-transition: opacity .25s ease-in-out;
}

.jigglewrapper {
  left: 50%;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.jiggleitem {
  -webkit-animation: jiggle 7s infinite;
  -moz-animation: jiggle 7s infinite;
  -o-animation: jiggle 7s infinite;
  animation: jiggle 7s infinite;
}

.bounceitem {
  -webkit-animation: bounce 3s linear 3s infinite normal running;
  -moz-animation: bounce 3s linear 3s infinite normal running;
  -o-animation: bounce 3s linear 3s infinite normal running;
  animation: bounce 3s linear 3s infinite normal running;
}

.velem {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.helem {
  text-align: center;
}

.cScale{
  transition: font-size 250ms ease-in;
}
.cScale.active{
  transition: font-size 250ms ease-in;
}
.cScale:hover {
  transition: font-size 250ms ease-in;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currentlysupported by Chrome and Opera */            
          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;
}
.pnormal{
  cursor: $cursor; //default
}
.threecolumn{
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
    -webkit-column-gap: 5vh; /* Chrome, Safari, Opera */
    -moz-column-gap: 5vh; /* Firefox */
    column-gap: 5vh;
}

//Keyboard interface
.keystd{
  width: 30px;
  height: 30px;
  margin: 10px;
  display: inline-block;
  background-color: green;
  text-align: center;
  -webkit-backface-visibility: hidden;
}

.kspn {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden; 
  color:#95268E;
  font-family: 'open-sans-bold';
}

.kspn-primary {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden; 
  color:black;
  font-family: 'open-sans-bold';
}

.kspn-seattle {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden; 
  color:#000;
  font-family: 'Lato';
}

.kspn-tampa {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden; 
  color:#143e77;
  font-family: 'open-sans-bold';
}
.pressin{
  transition: transform .125s ease-in-out;
  transform-origin: 50% 50%;
}
.pressin.active
{
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}
.keyopacity{
  transition: opacity 0.3s;
  opacity: 1
}
.keyopacity.keyhide{
  transition: opacity 0.3s;
  opacity: 0
}

.rotate90 {
	transform: rotate(-90deg);
	transform-origin: 100% 0;
	position: absolute;
}

.slideIn {
  max-width: 0;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  overflow-x: hidden;
  white-space: nowrap;
}

.slideOut {
  max-width: 50em;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  overflow-x: hidden;
  white-space: nowrap;
}


.sliding-container {
  position: relative;
  display: block;
  height: 50px;
  overflow: hidden;
  text-align: center;
}
.sliding {
  width:100%;
  height: 50px;
  line-height: 50px;
  text-align:center;
  top:0;
  position:absolute;
}

.sliding-enter {
  transform: translateX(100%); 
  transition: 0.3s transform ease-in-out;
}
.sliding-enter.sliding-enter-active {
  transform: translateX(0);
}
.sliding-leave {
  transform: translateX(0);
  transition: 0.3s transform ease-in-out;
}
.sliding-leave.sliding-leave-active {
  transform: translateX(-100%); 
}
.sliding-appear {
  transform: translateX(100%); 
  transition: 0.3s transform ease-in-out;
}
.sliding-appear.sliding-appear-active {
  transform: translateX(0);
}
.whiteSpaceAndBreaks{
  white-space: pre-wrap;
}
.fliph {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.leftToRight {
      left:100%;
      position:absolute;
      -webkit-animation:linear infinite normal;
      -webkit-animation-name: run;
      -webkit-animation-duration: 6s;
}     
@-webkit-keyframes run {
    0% { left: 0;}
    100%{ left : 200%;}
}
.opacity0{
  opacity: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  text-shadow: 0 0 48px #ffffff;

}
.opacity0small{
  opacity: 0;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);  
  text-shadow: 0 0 48px #ffffff;

}
.fadeablein {
-webkit-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
-moz-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
-o-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;

-webkit-transform: scale(1);
-moz-transform: scale(1);
-ms-transform: scale(1);
transform: scale(1);
opacity: 1;


}
.fadeableout {
  -webkit-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -moz-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  -o-transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  transition: transform 2s linear, opacity 2s linear, text-shadow 2s linear;
  opacity: 0;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);  

  text-shadow: 0 0 48px #ffffff;
}

.backgroundGradiant{
  background: 'red'; /* For browsers that do not support gradients */
  background: '-webkit-linear-gradient(-35deg, #923799, #0C909A)'; /* For Safari 5.1 to 6.0 */
  background: '-o-linear-gradient(-35deg, #923799, #0C909A)'; /* For Opera 11.1 to 12.0 */
  background: '-moz-linear-gradient(-35deg, #923799, #0C909A)'; /* For Firefox 3.6 to 15 */
  background: 'linear-gradient(-35deg, #923799, #0C909A)'; /* Standard syntax */
}

.recognitionOptions, .recognitionOptionsPrimary {
  /* padding: 40px; */
  overflow: hidden;
  border: 3px solid white;
  border-radius:30px;
  /* margin-top:50px; */
  position:absolute;
}

.recognitionOptions input, .recognitionOptionsPrimary input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.recognitionOptions label, .recognitionOptionsPrimary label {
float: left;
display: inline-block;
background-color:none;
color: white;
/* width: 200px; */
/* font-size: 18px; */
/* padding: 15px 20px; */
font-weight: bold;
text-align: center;
text-shadow: none;
border: none;
// -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
// box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
-webkit-transition: all 0.1s ease-in-out;
-moz-transition:    all 0.1s ease-in-out;
-ms-transition:     all 0.1s ease-in-out;
-o-transition:      all 0.1s ease-in-out;
transition:         all 0.1s ease-in-out;
}

.recognitionOptions label:hover, .recognitionOptionsPrimary label:hover {
cursor: pointer;
}

.recognitionOptions input:checked + label {
  background-color: white;
  color:#943187;
  font-weight: bold;
  border-radius: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index:9999;
}

.recognitionOptionsPrimary input:checked + label {
  background-color: white;
  color:#006699;
  font-weight: bold;
  border-radius: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index:9999;
}

.recognitionOptions label:first-of-type {
/* border-radius: 4px 0 0 4px; */
/* border-radius:25px; */
border-right:none;
border-top-right-radius: 25px;
border-bottom-right-radius: 25px;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
width:230px;
/* position:absolute; */
}

.recognitionOptions label:last-of-type {
/* border-radius: 0 4px 4px 0; */
/* border-radius:25px; */
border-left:none;
/* position:absolute; */
width:230px;
/* left:290px; */
border-top-left-radius:25px;
border-bottom-left-radius:25px;
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
}

.recognitionOptionsTampa {
/* padding: 40px; */
overflow: hidden;
/* border: 3px solid white; */
border-radius:30px;
/* margin-top:50px; */
position:absolute;
}

.recognitionOptionsTampa input {
position: absolute !important;
clip: rect(0, 0, 0, 0);
height: 1px;
width: 1px;
border: 0;
overflow: hidden;
}

.recognitionOptionsTampa label {
margin-right: 1vh;
background:white;
padding: 1.18125vh 1.0416667vh;
border-radius: 25px;
float:left;
display: inline-block;
background-color:none;
color:#694f28;
/* width: 200px; */
/* font-size: 18px; */
/* padding: 15px 20px; */
font-weight: bold;
text-align: center;
text-shadow: none;
border: none;
-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
-webkit-transition: all 0.1s ease-in-out;
-moz-transition:    all 0.1s ease-in-out;
-ms-transition:     all 0.1s ease-in-out;
-o-transition:      all 0.1s ease-in-out;
transition:         all 0.1s ease-in-out;
}

.recognitionOptionsTampa label:hover {
cursor: pointer;
}

.recognitionOptionsTampa input:checked + label {
padding: 0.78125vh 1.0416667vh;
background-color: #f6c68e;
color:#694f28;
font-weight: bold;
border-radius: 25px;
border-color: white;
border-width: 0.4vh;
border-style: solid;
-webkit-box-shadow: none;
box-shadow: none;
z-index:9999;
}

.recognitionOptionsTampa label:first-of-type {
/* border-radius: 4px 0 0 4px; */
/* border-radius:25px; */
padding: 1.18125vh 1.0416667vh;
border-right:none;
border-top-right-radius: 25px;
border-bottom-right-radius: 25px;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
width:230px;
/* position:absolute; */
}

.recognitionOptionsTampa label:last-of-type {
/* border-radius: 0 4px 4px 0; */
/* border-radius:25px; */
border-left:none;
/* position:absolute; */
width:230px;
/* left:290px; */
margin-right:0px!important;
border-top-left-radius:25px;
border-bottom-left-radius:25px;
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
}

.recognitionOptionsTampaFullPage h1 {
color:#694f28;
}

.primaryButton-tampa {
font-family: "OpenSans";
font-size: 1.04vh;
font-weight: bold;
line-height: 1.25vh;
text-transform: uppercase;
/* background: linear-gradient(135deg, #008F8F, #95268E); */
background: #102b77!important;
border: 0.26041666666666666667vh solid #FFF!important;
box-shadow: 0px .1vh .2vh 0px rgba(0, 0, 0, 0.5), 0px .05vh .05vh 0px rgba(82, 20, 78, 0.9), 0px 3px 8px 0px rgba(82, 20, 78, 0.5);
border-radius: 2.6vh;
color: white!important;
cursor: pointer;
display: flex;
/* height: 4.167vh; */
/* padding-bottom: 1.45vh; */
padding-left: 1vh;
padding-right: 1vh;
/* padding-top: 1.45vh; */
transition: box-shadow 0.125s ease-out;
white-space: nowrap;
}

.primaryButton {
  color: #95268E;
  font-family: "OpenSans";
  font-size: 1.04vh;
  font-weight: bold;
  line-height: 1.25vh;
  text-transform: uppercase;
  background: linear-gradient(135deg, #008F8F, #95268E);
  border: none;
  box-shadow: 0px .1vh .2vh 0px rgba(0, 0, 0, 0.5), 0px .05vh .05vh 0px rgba(82, 20, 78, 0.9), 0px 3px 8px 0px rgba(82, 20, 78, 0.5);
  border-radius: 2.6vh;
  color: white!important;
  cursor: pointer;
  display: flex;
  /* height: 4.167vh; */
  /* padding-bottom: 1.45vh; */
  padding-left: 1vh;
  padding-right: 1vh;
  /* padding-top: 1.45vh; */
  transition: box-shadow 0.125s ease-out;
  white-space: nowrap;
}

.primaryButton-primary {
  color: #95268E;
  font-family: "OpenSans";
  font-size: 1.04vh;
  font-weight: bold;
  line-height: 1.25vh;
  text-transform: uppercase;
  background: #009900!important;
  border: none;
  box-shadow: 0px .1vh .2vh 0px rgba(0, 0, 0, 0.5), 0px .05vh .05vh 0px rgba(121, 188, 67, 0.9), 0px 3px 8px 0px rgba(121, 188, 67, 0.5);
  border-radius: 2.6vh;
  color: white!important;
  cursor: pointer;
  display: flex;
  /* height: 4.167vh; */
  /* padding-bottom: 1.45vh; */
  padding-left: 1vh;
  padding-right: 1vh;
  /* padding-top: 1.45vh; */
  transition: box-shadow 0.125s ease-out;
  white-space: nowrap;
}

.primaryButton-keyboardSearch {
  color: white!important;
  border: none;
  /* height: 1.25vh!important; */
  margin-left: 0.4167vh;
  /* padding-top: 1.19vh; */
  width: 10.62vh;
}

.primaryButton-keyboardSearch .kspn {
  color: white!important;
}

.primaryButton-keyboardSearch .kspn-primary {
  color: white!important;
}

.gradient {
-webkit-mask-image: linear-gradient(0deg, transparent 1%, rgb(0, 0, 0) 20%);
}

.gradient-upper {
-webkit-mask-image: linear-gradient(180deg, transparent 1%, rgb(0, 0, 0) 20%);
}

.shadowed-image {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.clip-text {
  /* font-size: 6em;
  font-weight: bold; */
  line-height: 1;
  position: relative;
  display: inline-block;
  /* margin: .25em; */
  /* padding: .5em .75em; */
  text-align: center;
  /* Color fallback */
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.transparent-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}


/* Background */
.clip-text:before {
  position:"absolute";
  width:100%;
  height:100%;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit;
  /* background-size:cover; */
}

/* Text Background (black zone) */
.clip-text:after {
  position: absolute;
  z-index: -1;
  top: .125em;
  right: .125em;
  bottom: .125em;
  left: .125em;
  background-color: #000;
}

/* Change the background position to display letter when the black zone isn't here */
.clip-text--no-textzone:before {
  background-position: -.65em 0;
}

.clip-text--no-textzone:after {
  content: none;
}

/* Use Background-size cover for photo background and no-repeat background */
.clip-text--cover,
.clip-text--cover:before {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
            background-size: cover;
  background-position: 50% 50%;
}

.seattle-button:active {
  background-color:black;
}

.general-gradient-line {
  background: rgba(44,44,44,1);
background: -moz-linear-gradient(top, rgba(44,44,44,1) 0%, rgba(0,0,0,1) 0%, rgba(28,28,28,0.85) 9%, rgba(43,43,43,0.72) 17%, rgba(17,17,17,0.53) 29%, rgba(0,0,0,0.33) 41%, rgba(18,18,18,0.3) 43%, rgba(71,71,71,0.3) 49%, rgba(102,102,102,0.3) 68%, rgba(89,89,89,0.3) 82%, rgba(0,0,0,0.3) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(44,44,44,1)), color-stop(0%, rgba(0,0,0,1)), color-stop(9%, rgba(28,28,28,0.85)), color-stop(17%, rgba(43,43,43,0.72)), color-stop(29%, rgba(17,17,17,0.53)), color-stop(41%, rgba(0,0,0,0.33)), color-stop(43%, rgba(18,18,18,0.3)), color-stop(49%, rgba(71,71,71,0.3)), color-stop(68%, rgba(102,102,102,0.3)), color-stop(82%, rgba(89,89,89,0.3)), color-stop(100%, rgba(0,0,0,0.3)));
background: -webkit-linear-gradient(top, rgba(44,44,44,1) 0%, rgba(0,0,0,1) 0%, rgba(28,28,28,0.85) 9%, rgba(43,43,43,0.72) 17%, rgba(17,17,17,0.53) 29%, rgba(0,0,0,0.33) 41%, rgba(18,18,18,0.3) 43%, rgba(71,71,71,0.3) 49%, rgba(102,102,102,0.3) 68%, rgba(89,89,89,0.3) 82%, rgba(0,0,0,0.3) 100%);
background: -o-linear-gradient(top, rgba(44,44,44,1) 0%, rgba(0,0,0,1) 0%, rgba(28,28,28,0.85) 9%, rgba(43,43,43,0.72) 17%, rgba(17,17,17,0.53) 29%, rgba(0,0,0,0.33) 41%, rgba(18,18,18,0.3) 43%, rgba(71,71,71,0.3) 49%, rgba(102,102,102,0.3) 68%, rgba(89,89,89,0.3) 82%, rgba(0,0,0,0.3) 100%);
background: -ms-linear-gradient(top, rgba(44,44,44,1) 0%, rgba(0,0,0,1) 0%, rgba(28,28,28,0.85) 9%, rgba(43,43,43,0.72) 17%, rgba(17,17,17,0.53) 29%, rgba(0,0,0,0.33) 41%, rgba(18,18,18,0.3) 43%, rgba(71,71,71,0.3) 49%, rgba(102,102,102,0.3) 68%, rgba(89,89,89,0.3) 82%, rgba(0,0,0,0.3) 100%);
background: linear-gradient(to bottom, rgba(44,44,44,1) 0%, rgba(0,0,0,1) 0%, rgba(28,28,28,0.85) 9%, rgba(43,43,43,0.72) 17%, rgba(17,17,17,0.53) 29%, rgba(0,0,0,0.33) 41%, rgba(18,18,18,0.3) 43%, rgba(71,71,71,0.3) 49%, rgba(102,102,102,0.3) 68%, rgba(89,89,89,0.3) 82%, rgba(0,0,0,0.3) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2c2c', endColorstr='#000000', GradientType=0 );
}

.miami-bullet li {
  font-size: 1.5625vh;
}

.miami-subtitle p {
  margin-block-start: 0.6510vh;
}

.miami-video video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.seattle-video::-webkit-media-controls {
  display: none;
}

// .miami-video video::-webkit-media-controls-panel {
//   display: none;
// }

.alpha-target {
  mask-mode: alpha;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 1.6%;
  -webkit-mask-position-x: center;
}

/* Background image from http://thepatternlibrary.com/ and http://lorempixel.com */
/* .clip-text_one {
    background-image: url(https://4kwallpapers.co/wp-content/uploads/2015/09/abstract-colorful-vectors-design-4k-wallpapers.png);
} */

/*# sourceMappingURL=index.css.map */

.p-without-margin > p {
  margin: 0px!important;
}

html {
  -webkit-tap-highlight-color: transparent !important;
}

.totally-centered {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.preview-slide {
  max-width: 100%;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.video-no-fullscreen video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.whiteBottomTransparentTop {
  background-image: -webkit-linear-gradient(-90deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 100%);
background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 100%);
background-image: linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 100%);
background-position: 50% 50%;
-webkit-background-origin: padding-box;
background-origin: padding-box;
-webkit-background-clip: border-box;
background-clip: border-box;
-webkit-background-size: auto auto;
background-size: auto auto;
}


.transparentTopWhiteBottom {
  background-image: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 100%);
background-image: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 100%);
background-image: linear-gradient(0deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 100%);
background-position: 50% 50%;
-webkit-background-origin: padding-box;
background-origin: padding-box;
-webkit-background-clip: border-box;
background-clip: border-box;
-webkit-background-size: auto auto;
background-size: auto auto;
}

.quotation-mark:before {
  content: '“';
}

.coburg-donor-lists_donor-lists-items p {
  cursor: pointer;
}
