@font-face {
    font-family: 'aleo-light';
    src: url('../../fonts/aleo/Aleo-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleo-bolditalic';
    src: url('../../fonts/aleo/Aleo-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aleo-bold';
    src: url('../../fonts/aleo/Aleo-Bold.ttf') format('truetype');
}

.aleo-light {
    font-family: 'aleo-light', Sans-Serif;
}

.aleo-bold {
    font-family: 'aleo-bold', Sans-Serif;
}

.aleo-bolditalic {
    font-family: 'aleo-bolditalic', Sans-Serif;
}