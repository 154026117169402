/* neuton-regular - latin */
@font-face {
    font-family: 'Neuton';
    font-style: normal;
    font-weight: 400;
    src: local('Neuton Regular'), local('Neuton-Regular'),
        url('../../fonts/neuton/neuton-v9-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/neuton/neuton-v9-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.gfont_neuton_serif {
    font-family: 'Neuton', sans-serif;
}

.gfont_neuton_serif {
    font-family: 'Neuton', Sans-Serif;
}