@font-face {
    font-family: 'crimson-regular';
    src: url("../../fonts/crimson/CrimsonText-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'crimson-semi-bold';
    src: url("../../fonts/crimson/CrimsonText-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.crimson-semi-bold {
    font-family: 'crimson-semi-bold', Sans-Serif;
}

.crimson-regular {
    font-family: 'crimson-regular', Sans-Serif;
}