@font-face {
    font-family: 'bembo-semibold';
    font-style: normal;
    font-weight: 600;
    src: local('Bembo'), local('Bebmbo-Semibold'),
        url("../../fonts/bembo/BemboStd-Semibold.woff2") format('woff2'),
        url("../../fonts/bembo/BemboStd-Semibold.woff") format('woff');
}

.ql-font-bembo-semibold,
.ql-font span[data-value="bembo-semibold"]::before {
  font-family: "bembo-semibold"
}
