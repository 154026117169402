/* fira-sans-regular - latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
        url('../../fonts/fira-sans/fira-sans-v7-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/fira-sans/fira-sans-v7-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.gfont_fira {
    font-family: 'Fira Sans', Sans-Serif;
}