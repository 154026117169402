@font-face {
  font-family: 'optima';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/optima/optima.ttf') format('truetype');
}

@font-face {
  font-family: 'optima-bold';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/optima/optima_bold.ttf') format('truetype');
}

.ql-font-optima,
.ql-font span[data-value="optima"]::before {
  font-family: "optima"
}


.ql-font-optima-bold,
.ql-font span[data-value="optima-bold"]::before {
  font-family: "optima-bold"
}
