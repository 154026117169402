@font-face {
    font-family: 'robotoslabregular';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto-Slab-Regular'), local('Roboto-Slab-Regular'),
        url('../../fonts/roboto-slab/RobotoSlab-Regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/roboto-slab/RobotoSlab-Regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ql-font-robotoslabregular,
.ql-font span[data-value="robotoslabregular"]::before {
    font-family: "robotoslabregular";
}