@font-face {
    font-family: 'meta-pro-bold';
    font-weight: 700;
    font-style: normal;
    src: local("Meta"), local("Meta-Pro-Bold"),
        url("../../fonts/meta-pro/FFMetaProBold.woff2") format("woff2"),
        url("../../fonts/meta-pro/FFMetaProBold.woff") format("woff");
}

@font-face {
    font-family: 'meta-pro';
    font-weight: 400;
    font-style: normal;
    src: local("Meta"), local("Meta-Pro-Book"),
        url("../../fonts/meta-pro/FFMetaProBook.woff2") format("woff2"),
        url("../../fonts/meta-pro/FFMetaProBook.woff") format("woff");
}

@font-face {
    font-family: 'meta-pro-light';
    font-weight: 300;
    font-style: normal;
    src: local("Meta"), local("Meta-Pro-Light"),
        url("../../fonts/meta-pro/FFMetaProLight.woff2") format("woff2"),
        url("../../fonts/meta-pro/FFMetaProLight.woff") format("woff");
}

.ql-font-meta-pro,
.ql-font span[data-value="meta-pro"]::before {
    font-family: "meta-pro";
}

.ql-font-meta-pro-bold,
.ql-font span[data-value="meta-pro-bold"]::before {
    font-family: "meta-pro-bold";
}

.ql-font-meta-pro-light,
.ql-font span[data-value="meta-pro-light"]::before {
    font-family: "meta-pro-light";
}