
.denver-stories {

  &__container {
    height: 100vh;
    width: 100vw;
    position: absolute;
  }
  
  &__content {
    height: 100vh;
    width: 100vw;
    z-index: 10;
    position: absolute;
  }
  
  &__heading {
    padding: 0;
    margin: 0;
  }
  
  &__main-copy {
    padding: 0 6vw 0 6vw;
  }
  
  &__image {
    width: 46vw;
  }
  
  &__quote {
    line-height: 1.75;
    margin: 0;
    padding-top: 3vh;
    padding-bottom: 1vh;
  }
  
  &__quote-footer {
    padding: 1.5vh 0;
  }
  
  &__two-cols {
    display: flex;
    margin: 3vh 0;
    justify-content: space-between;
  }
  
  &__centered-col {
    display: flex;
    margin: 3vh 0;
    justify-content: center;
  }

  &__col {
    flex: 0 0 48%;
    &:nth-child(2) {
      margin-left: 3.5vw;
      //border: 1px solid red;
    }
  }

  &__image-container {
    margin: 0;
    width: inherit;
    text-align: center;
  }

  &__image-container-background {
    background: #abb3a7;
    opacity: 0.5;
    width: 38.51vw;
    height: 34.72vw;
    position: absolute;
  }

  &__image-container-table {
    width: 38.51vw;
    height: 34.72vw;
    display: table;
  }

  &__image-container-table-cell {
    display: table-cell;
    vertical-align: middle;
  }

  &__image-container-table-cell > img {
    position: relative!important;
    max-height: 34.72vw!important;
    max-width: 38.51vw!important;
  }

  &__image-container-image {
    margin: 0;
    // width: 38vw;
    // height: 19vh;
    //max-width: 40vw;
    //width: 23.296vh;
    // max-height: 19.53vh;
    position: relative!important;
    max-height:100%!important;
    max-width: 100%;
  }

  &__image-container-caption {
    margin: 0;
    font-size: calc(100vh * 0.009);
    font-family: 'Roboto Medium';
    text-align: left;
    padding: 0.8vh 0;
  }

  &__subhead, &__body {
    margin-bottom: 2vh;
    padding-right: 3vw;
  }

  &__story-1 {
    position: relative;
    text-align: center;
    padding: 7vh 7vw;
  }
  
  &__story-2,  &__story-3,  &__story-4 {
    position: relative;
    padding: 3vh 7vw 0 9vw;
  }
}
