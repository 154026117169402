@font-face {
  font-family: 'pantograph-wide-bold';
  font-style: normal;
  src: url("../../fonts/pantograph-wide/pantograph-wide-bold.otf") format('opentype'),
}

@font-face {
  font-family: 'pantograph-wide-regular';
  font-style: normal;
  src: url("../../fonts/pantograph-wide/pantograph-wide-regular.otf") format('opentype'),
}

@font-face {
  font-family: 'pantograph-wide-light';
  font-style: normal;
  src: url("../../fonts/pantograph-wide/pantograph-wide-light.otf") format('opentype'),
}

.ql-font-pantograph-wide-bold,
.ql-font span[data-value="pantograph-wide-bold"]::before {
  font-family: "pantograph-wide-bold"
}

.ql-font-pantograph-wide-regular,
.ql-font span[data-value="pantograph-wide-regular"]::before {
  font-family: "pantograph-wide-regular"
}

.ql-font-pantograph-wide-light,
.ql-font span[data-value="pantograph-wide-light"]::before {
  font-family: "pantograph-wide-light"
}
