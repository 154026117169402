@font-face {
    font-family: 'hind-light';
    src: url('../../fonts/hind/Hind-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hind-regular';
    src: url('../../fonts/hind/Hind-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ql-font-hindregular,
.ql-font span[data-value="hind-regular"]::before {
    font-family: "hind-regular"
}

.ql-font-hindlight,
.ql-font span[data-value="hind-light"]::before {
    font-family: "hind-light"
}