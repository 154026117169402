// Transition Fadein and FadeOut in 500ms
.transition-fade-enter {
  opacity: 0;
}
.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.transition-fade-exit {
  opacity: 1;
}
.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

// Transition dissolve
.transition-dissolve-enter {
  opacity: 1;
}
.transition-dissolve-enter-active {
  opacity: 1;
}
.transition-dissolve-exit {
  opacity: 1;
}
.transition-dissolve-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 1250ms;
}

// Transition Fadein 500ms and FadeOut in 1s
.transition-fade500ms1s-enter {
  opacity: 0;
}
.transition-fade500ms1s-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.transition-fade500ms1s-exit {
  opacity: 1;
}
.transition-fade500ms1s-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

// Transition Fadein and FadeOut in 250ms
.transition-fade250ms-enter {
  opacity: 0;
}
.transition-fade250ms-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.transition-fade250ms-exit {
  opacity: 1;
}
.transition-fade250ms-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

// Transition Fadein and FadeOut in 1s
.transition-fade1s-enter {
  opacity: 0;
}
.transition-fade1s-enter-active {
opacity: 1;
transition: opacity 1000ms ease-in;
}
.transition-fade1s-exit {
opacity: 1;
}
.transition-fade1s-exit-active {
opacity: 0;
transition: opacity 1000ms ease-in;
}

// Transition pop-up and FadeOut in 500ms
.transition-fadeout-enter {
  opacity: 1;
}
.transition-fadeout-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.transition-fadeout-exit {
  opacity: 1;
}
.transition-fadeout-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

// Transition Fadein and Fast FadeOut
.transition-slowinfastout-enter {
  opacity: 0;
}
.transition-slowinfastout-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.transition-slowinfastout-exit {
  opacity: 1;
}
.transition-slowinfastout-exit-active {
  opacity: 0;
  transition: opacity 10ms ease-in;
}

.transition-slide-in-from-right-enter {
  z-index:999;
  left: 100%;
  position: absolute;
}

// Transition Fadein and Fast FadeOut
.transition-fastinslowout-enter {
  opacity: 1;
}
.transition-fastinslowout-enter-active {
  opacity: 1;
  transition: opacity 10ms ease-in;
}
.transition-fastinslowout-exit {
  opacity: 1;
}
.transition-fastinslowout-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.transition-slide-in-from-right-enter {
  z-index:999;
  left: 100%;
  position: absolute;
}

// Transition Slide in from right
.transition-slide-in-from-right-enter-active {
  z-index:999;
  left: 0%;
  transition: left linear 1s;
}

.transition-slide-in-from-right-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-right-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

// Transition Slide in from left

.transition-slide-in-from-left-enter {
  z-index:999;
  left: -100%;
  position: absolute;
}

.transition-slide-in-from-left-enter-active {
  z-index:999;
  left: 0%;
  transition: left linear 1s;
}

.transition-slide-in-from-left-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-left-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}


// Transition Slide in from top

.transition-slide-in-from-top-enter {
  z-index:999;
  top: -100%;
  position: absolute;
}

.transition-slide-in-from-top-enter-active {
  z-index:999;
  top: 0%;
  transition: top linear 500ms;
}

.transition-slide-in-from-top-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-top-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

// Transition Slide in from bottom

.transition-slide-in-from-bottom-enter {
  z-index:999;
  top: 100%;
  position: absolute;
}

.transition-slide-in-from-bottom-enter-active {
  z-index:999;
  top: 0%;
  transition: top linear 500ms;
}

.transition-slide-in-from-bottom-exit {
  z-index: 0;
  opacity: 1;
}

.transition-slide-in-from-bottom-exit-active {
  transition: opacity 500ms ease-in;
  transition-delay: 500ms;
  opacity: 0;
}

// Transition FadeIn FadeOut 2 Seconds
.transition-fade-2s-enter {
  opacity: 0;
}
.transition-fade-2s-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}
.transition-fade-2s-exit {
  opacity: 1;
}
.transition-fade-2s-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

.transition-fadeout-2s-enter {
  opacity: 1;
}
.transition-fadeout-2s-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}
.transition-fadeout-2s-exit {
  opacity: 1;
}
.transition-fadeout-2s-exit-active {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

.transition-fade-2s-linear-enter {
  opacity: 0;
}
.transition-fade-2s-linear-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}
.transition-fade-2s-linear-exit {
  opacity: 1;
}
.transition-fade-2s-linear-exit-active {
  opacity: 0;
  transition: opacity 2000ms linear;
}