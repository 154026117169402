/* merriweather-regular - latin */
@font-face {
    font-family: 'merriweather-regular';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather'), local('Merriweather-Regular'),
        url('../../fonts/merriweather/merriweather-v17-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../../fonts/merriweather/merriweather-v17-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* merriweather-light */

@font-face {
    font-family: 'merriweather-light';
    font-style: normal;
    font-weight: 300;
    src: local('Merriweather Light'), local('Merriweather-Light'),
        url("../../fonts/merriweather/merriweather-light.woff2") format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../../fonts/merriweather/merriweather-light.woff") format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* merriweather-bold */

@font-face {
    font-family: 'merriweather-bold';
    font-style: normal;
    font-weight: 300;
    src: local('Merriweather Light'), local('Merriweather-Light'),
        url("../../fonts/merriweather/merriweather-bold.woff2") format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../../fonts/merriweather/merriweather-bold.woff") format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ql-font-merriweather-regular,
.ql-font span[data-value="merriweather-regular"]::before {
    font-family: "merriweather-regular"
}

.ql-font-merriweather-light,
.ql-font span[data-value="merriweather-light"]::before {
    font-family: "merriweather-light"
}

.ql-font-merriweather-bold,
.ql-font span[data-value="merriweather-bold"]::before {
    font-family: "merriweather-bold"
}
