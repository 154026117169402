@font-face {
  font-family: 'rector';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/rector/rector.ttf') format('truetype');
}

@font-face {
  font-family: 'rector-medium';
  font-weight: 400;
  font-style: normal;
  src: local("Rector Medium"),
  url("../../fonts/rector/rector-medium.woff2") format("woff2")
}

.ql-font-rector,
.ql-font span[data-value="rector"]::before {
  font-family: "rector"
}

.ql-font-rector-medium,
.ql-font span[data-value="rector-medium"]::before {
  font-family: "rector-medium";
}
